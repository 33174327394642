import React, { useContext, useState, useEffect } from "react";
import { getUserConfig } from "../../firebase";
import CenteredCircularProgress from "../CenteredCircularProgress";

const ConfigContext = React.createContext();

export function useConfig() {
  return useContext(ConfigContext);
}

export default function ConfigProvider({ uid, children }) {
  const [config, setConfig] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = getUserConfig(uid, (config) => {
      setConfig(config);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <ConfigContext.Provider value={config}>
      {!loading && children}
    </ConfigContext.Provider>
  );
}
