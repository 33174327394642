import { Paper, Fade } from "@mui/material";

const timeout = 700;

export default function PageCard(props) {
  return (
    <Fade in timeout={timeout}>
      <Paper
        variant="outlined"
        sx={{ maxWidth: "95vw", width: "100%", minHeight: "100%", borderColor: "primary.main",  }}
        {...props}
      />
    </Fade>
  );
}
