import React, { useState, useEffect } from "react";
import { query, onSnapshot, collection, where, doc } from "firebase/firestore";
import { db } from "../../../../firebase";
import { useConfig } from "../../../context/ConfigContext";
import OrderTabs from "../OrderTabs";
import CenteredCircularProgress from "../../../CenteredCircularProgress";
import PageCard from "./PageCard";

export default function ActiveOrders({ currentUser, selectedBranch }) {
  const [standardOrders, setStandardOrders] = useState(); //! must be null to render content in right order
  const [customOrders, setCustomOrders] = useState(); //! must be null to render content in right order

  const config = useConfig();

  useEffect(() => {
    const standardOrderQuery = query(
      collection(db, `users/${currentUser.uid}/orders`),
      where("active", "==", true),
      where("accepted", "in", [true, !config.autoAcceptStandardOrders]),
      where(
        "selectedBranch",
        "==",
        doc(db, `users/${currentUser.uid}/branches`, selectedBranch.id)
      )
    );
    const unsubscribeStandardOrderQuery = onSnapshot(
      standardOrderQuery,
      (querySnapshot) => {
        const newOrders = [];
        querySnapshot.forEach((doc) => {
          newOrders.push({ id: doc.id, data: doc.data() });
        });
        setStandardOrders(newOrders);
      }
    );

    const customOrderQuery = query(
      collection(db, `users/${currentUser.uid}/customOrders`),
      where("active", "==", true),
      where("accepted", "==", true),
      where(
        "selectedBranch",
        "==",
        doc(db, `users/${currentUser.uid}/branches`, selectedBranch.id)
      )
    );
    const unsubscribeCustomOrderQuery = onSnapshot(
      customOrderQuery,
      (querySnapshot) => {
        const newOrders = [];
        querySnapshot.forEach((doc) => {
          newOrders.push({ id: doc.id, data: doc.data() });
        });
        setCustomOrders(newOrders);
      }
    );

    return () => {
      unsubscribeStandardOrderQuery();
      unsubscribeCustomOrderQuery();
    };
  }, [selectedBranch]);

  return (
    <PageCard>
      {standardOrders && customOrders ? (
        <OrderTabs
          standartOrders={standardOrders}
          customOrders={customOrders}
          buttonAction="collected"
          selectedBranch={selectedBranch}
        />
      ) : (
        <CenteredCircularProgress />
      )}
    </PageCard>
  );
}
