import {
  Alert,
  Badge,
  Box,
  Button,
  Collapse,
  IconButton,
  Stack,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { getProducts, getUserConfig } from "../../../../firebase";
import { useAuth } from "../../../context/AuthContext";
import { productHasPrice } from "../../../../util/tools";

export default function ProductManagerToolbar({
  selected,
  onRemove,
  onActivate,
  onDeactivate,
  onAdd,
}) {
  const buttonStyle = { margin: 3, width: 240 };
  const [infosOpen, setInfosOpen] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const { currentUser } = useAuth();
  useEffect(() => {
    getProducts(currentUser.uid, (products) => {
      if (products.filter((p) => !productHasPrice(p)).length > 0) {
        getUserConfig(currentUser.uid, (config) => {
          if (config.showPrices) setShowAlert(true);
        });
      } else {
        setShowAlert(false);
      }
    });
  }, []);
  const handelInfoClick = () => {
    // !showAlert && setShowAlert(true);
    setInfosOpen(!infosOpen);
  };

  return (
    <>
      <Box justifyContent="space-between" display="flex" flexWrap="wrap">
        {selected ? (
          <Stack direction="row" flexWrap="wrap">
            <Button
              size="large"
              variant="contained"
              sx={buttonStyle}
              onClick={onActivate}
            >
              Auswahl aktivieren
            </Button>
            <Button
              size="large"
              variant="outlined"
              sx={buttonStyle}
              onClick={onDeactivate}
            >
              Auswahl deaktivieren
            </Button>
            <Button
              size="large"
              variant="outlined"
              color="error"
              sx={buttonStyle}
              onClick={onRemove}
            >
              Entfernen
            </Button>
          </Stack>
        ) : (
          <Button
            size="large"
            variant="contained"
            color="primary"
            sx={buttonStyle}
            onClick={onAdd}
          >
            Hinzufügen
          </Button>
        )}
        <IconButton sx={{ margin: 3 }} onClick={handelInfoClick}>
          <Badge badgeContent={showAlert ? 1 : 0} color="secondary">
            <InfoIcon />
          </Badge>
        </IconButton>
        {showAlert && (
          <Collapse sx={{ width: "100%", mx: 3, mb: 3 }} in={infosOpen}>
            <Alert
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setInfosOpen(false)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Um ein Produkt aktivieren zu können, müssen Name und Preis
              angegeben werden. Sollen Produkte ohne Preis angezeigt werden,
              müssen die Kontoeinstellungen die Einstellungen geändert werden.
            </Alert>
          </Collapse>
        )}
      </Box>
    </>
  );
}
