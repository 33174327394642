import React from "react";
import PropTypes, { object } from "prop-types";
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import blurry_gradient_blue from "./images/blurry_gradient_blue.png";
import blurry_gradient_red from "./images/blurry_gradient_red.png";
import waves from "./images/waves.svg";

const useStyles = makeStyles({
  root: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: (props) =>
      props.color === "red"
        ? `url(${blurry_gradient_red})`
        : `url(${blurry_gradient_blue})`,
    color: "white",
    //     width: 300,
    //     height: 400,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
    letterSpacing: 2,
  },
  left: {
    height: "200px",
    transform: `translateX(-470px) scaleX(-1)`,
    userSelect: "none",
  },
  right: {
    height: "150px",
    transform: `translateX(-450px) `,
    userSelect: "none",
  },
});

export default function PaperWithWaves(props) {
  const { color, side, waveOffset, ...other } = props;
  const classes = useStyles(props);
  return (
    <Paper className={classes.root} {...other} elevation={20}>
      <img
        src={waves}
        alt=""
        className={side === "left" ? classes.left : classes.right}
      />
      <img
        src={waves}
        alt=""
        style={{
          height: "170px",
          width: "300%",
          transform: `rotate(180deg) translateX(${waveOffset}px)`,
          opacity: "50%",
          marginTop: "auto",
          userSelect: "none",
        }}
      />
      <Box sx={{ marginTop: "1rem" }} position="absolute" width="80%">
        <Typography
          variant="h3"
          component="div"
          fontFamily=""
          sx={{
            opacity: 0.8,
            backgroundImage:
              props.color === "red"
                ? "linear-gradient(90deg, #FCAF3C, #fc3d3d)"
                : "linear-gradient(90deg, #008DE8, #7FDED5)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundClip: "text",
            color: "transparent",
            margin: 0,
            fontWeight: "500",
          }}
          padding={1}
        >
          {props.title}
        </Typography>
        {props.children}
      </Box>
    </Paper>
  );
}

PaperWithWaves.propTypes = {
  color: PropTypes.oneOf(["blue", "red"]),
  side: PropTypes.oneOf(["left", "right"]).isRequired,
  waveOffset: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};
