import { useState, useEffect, useRef } from "react";
import {
  Typography,
  Tooltip,
  IconButton,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  Alert,
} from "@mui/material";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import CenteredCircularProgress from "../../CenteredCircularProgress";
import {
  getProducts,
  getUserConfig,
  updateUserConfig,
} from "../../../firebase";
import { useAuth } from "../../context/AuthContext";
import { useConfig } from "../../context/ConfigContext";
import ConfigSwitch from "./ConfigSwitch";
import SettingsCard from "./SettingsCard";
import { productHasPrice } from "../../../util/tools";

export default function Configuration() {
  const { currentUser } = useAuth();
  const [currentConfig, setCurrentConfig] = useState();
  const config = useConfig();

  const [offeredProducts, setOfferedProducts] = useState();
  const [loading, setLoading] = useState(true);

  const handleChange = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;
    setCurrentConfig((prev) => ({ ...prev, [name]: checked }));
  };

  useEffect(() => {
    updateUserConfig(currentUser.uid, { ...currentConfig });
  }, [currentConfig]);

  useEffect(() => {
    const unsubscribe = getProducts(currentUser.uid, (products) => {
      setOfferedProducts(products);
      setCurrentConfig(config);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  function priceCanBeShown() {
    if (!offeredProducts.length) return false;
    let canBeShown = true;
    offeredProducts.forEach((product) => {
      const price = product.price;
      const active = product.active;
      if (!productHasPrice(product) && active) canBeShown = false;
    });
    return canBeShown;
  }

  return (
    <div>
      {loading ? (
        <CenteredCircularProgress />
      ) : (
        <SettingsCard>
          <Typography variant="h2" component="h2" mb={2}>
            Konfiguration
          </Typography>
          {!priceCanBeShown() && (
            <Alert severity="info" sx={{ mb: 1 }}>
              Preise können erst angezeigt werde, wenn für jedes aktivierte
              Produkt ein Preis angegeben wurde.
            </Alert>
          )}
          <FormGroup>
            <ConfigSwitch
              name="showPrices"
              label="Preise Anzeigen"
              defaultChecked={currentConfig.showPrices}
              checked={currentConfig.showPrices}
              onChange={handleChange}
              disabled={!priceCanBeShown()}
            />
            <ConfigSwitch
              name="allowCustomOrders"
              label="Sonderbestellungen zulassen"
              defaultChecked={currentConfig.allowCustomOrders}
              checked={currentConfig.allowCustomOrders}
              onChange={handleChange}
            />
            <ConfigSwitch
              name="autoAcceptStandardOrders"
              label="Standartbestellungen automatisch annehmen"
              defaultChecked={currentConfig.autoAcceptStandardOrders}
              checked={currentConfig.autoAcceptStandardOrders}
              onChange={handleChange}
            />
          </FormGroup>
        </SettingsCard>
      )}
    </div>
  );
}
