import { Divider, Grid, Typography } from "@mui/material";

export default function SectionTitle({ title, disableTopSpace }) {
  return (
    <Grid item xs={12} sx={{ mt: disableTopSpace ? 0 : 3 }}>
      <Typography variant="h4">{title}</Typography>
      {/*<Divider />*/}
    </Grid>
  );
}
