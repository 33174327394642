import { useState, useEffect, Component } from "react";
import Layout from "../../layout/Layout";
import { Dashboard as DashboardIcon } from "@mui/icons-material";
import { useAuth } from "../../context/AuthContext";
import ConfigProvider, { useConfig } from "../../context/ConfigContext";
import { getBranches } from "../../../firebase";
import ActiveOrders from "./pages/ActiveOrders";
import AddOrder from "./pages/addOrders/AddOrder";
import NewOrders from "./pages/NewOrders";
import Dashboard from "./pages/dashboard/Dashboard";
import ProductManager from "./pages/ProductManager";

const pages = [
  // { Component: Dashboard },
  { Component: ActiveOrders },
  { Component: AddOrder },
  { Component: NewOrders },
  { Component: ProductManager },
];

export default function OrderManager() {
  const { currentUser } = useAuth();
  const [page, setPage] = useState(0);
  const [loadingBranches, setLoadingBranches] = useState(true);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(branches[0]); //TODO: set default do user specific

  useEffect(() => {
    getBranches(currentUser.uid, (branches) => {
      setBranches(branches);
      setSelectedBranch(branches[0]);
      setLoadingBranches(false);
    });
  }, []);

  const handleChange = (page) => {
    setPage(page);
  };

  const Page = pages[page].Component;

  return (
    <Layout
      onPageChange={handleChange}
      page={page}
      branches={branches}
      selectedBranch={selectedBranch}
      setSelectedBranch={setSelectedBranch}
      loadingBranches={loadingBranches}
    >
      <Page currentUser={currentUser} selectedBranch={selectedBranch} />
    </Layout>
  );
}
