import React from "react";
import { Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

export default function CenteredCircularProgress() {
  return (
    <Box
      sx={{
        padding: 3,
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
