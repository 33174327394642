import { Container, Typography, Box, Stack, Link } from "@mui/material";
import React from "react";

export default function DataProtection() {
  return (
    <Container maxWidth="md" sx={{ py: 10 }}>
      <Stack spacing={2}>
        <Typography component="h1" variant="h1">
          Datenschutzerklärung
        </Typography>
        <Typography component="h2" variant="h2">
          Allgemeiner Hinweis und Pflichtinformationen
        </Typography>
        <Typography component="h2" variant="h5">
          Benennung der verantwortlichen Stelle
        </Typography>
        <Typography>
          Benennung der verantwortlichen Stelle Die verantwortliche Stelle für
          die Datenverarbeitung auf dieser Website ist:
        </Typography>
        <Box paddingLeft={2}>
          <Typography>DAILYORGANIZED.COM</Typography>
          <Typography>Sebastian Hendel</Typography>
          <Typography>Schützenstraße 59</Typography>
          <Typography>08468 Reichenbach</Typography>
        </Box>
        <Typography>
          Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
          anderen über die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
        </Typography>
        <Typography component="h2" variant="h5">
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </Typography>
        <Typography>
          Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
          Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
          Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
          formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
          Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </Typography>
        <Typography component="h2" variant="h5">
          Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
        </Typography>
        <Typography>
          Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
          Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen
          ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der
          Sitz unseres Unternehmens befindet. Der folgende Link stellt eine
          Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:{" "}
          <Link href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </Link>
        </Typography>
        <Typography component="h2" variant="h5">
          Recht auf Datenübertragbarkeit
        </Typography>
        <Typography>
          Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
          Einwilligung oder in Erfüllung eines Vertrags automatisiert
          verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
          Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie
          die direkte Übertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </Typography>
        <Typography component="h2" variant="h5">
          Recht auf Auskunft, Berichtigung, Sperrung, Löschung
        </Typography>
        <Typography>
          Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
          Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu
          weiteren Fragen zum Thema personenbezogene Daten können Sie sich
          jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an
          uns wenden.
        </Typography>
        <Typography component="h2" variant="h5">
          SSL- bzw. TLS-Verschlüsselung
        </Typography>
        <Typography>
          Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
          Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere
          Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie
          über diese Website übermitteln, für Dritte nicht mitlesbar. Sie
          erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile
          Ihres Browsers und am Schloss-Symbol in der Browserzeile.
        </Typography>
        <Typography component="h2" variant="h5">
          Registrierung auf dieser Website
        </Typography>
        <Typography>
          Zur Nutzung bestimmter Funktionen können Sie sich auf unserer Website
          registrieren. Die übermittelten Daten dienen ausschließlich zum Zwecke
          der Nutzung des jeweiligen Angebotes oder Dienstes. Bei der
          Registrierung abgefragte Pflichtangaben sind vollständig anzugeben.
          Andernfalls werden wir die Registrierung ablehnen.
        </Typography>
        <Typography>
          Im Falle wichtiger Änderungen, etwa aus technischen Gründen,
          informieren wir Sie per E-Mail. Die E-Mail wird an die Adresse
          versendet, die bei der Registrierung angegeben wurde.
        </Typography>
        <Typography>
          Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
          auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein
          Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich.
          Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die
          Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom
          Widerruf unberührt.
        </Typography>
        <Typography>
          Wir speichern die bei der Registrierung erfassten Daten während des
          Zeitraums, den Sie auf unserer Website registriert sind. Ihren Daten
          werden gelöscht, sollten Sie Ihre Registrierung aufheben. Gesetzliche
          Aufbewahrungsfristen bleiben unberührt.
        </Typography>
        <Typography component="h2" variant="h5">
          Cookies
        </Typography>
        <Typography>
          Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr
          Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns dabei,
          unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
        </Typography>
        <Typography>
          Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende
          Ihrer Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere
          Cookies auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen.
          Solche Cookies helfen uns, Sie bei Rückkehr auf unserer Website
          wiederzuerkennen.
        </Typography>
        <Typography>
          Mit einem modernen Webbrowser können Sie das Setzen von Cookies
          überwachen, einschränken oder unterbinden. Viele Webbrowser lassen
          sich so konfigurieren, dass Cookies mit dem Schließen des Programms
          von selbst gelöscht werden. Die Deaktivierung von Cookies kann eine
          eingeschränkte Funktionalität unserer Website zur Folge haben.
        </Typography>
        <Typography>
          Das Setzen von Cookies, die zur Ausübung elektronischer
          Kommunikationsvorgänge oder der Bereitstellung bestimmter, von Ihnen
          erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf
          Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website
          haben wir ein berechtigtes Interesse an der Speicherung von Cookies
          zur technisch fehlerfreien und reibungslosen Bereitstellung unserer
          Dienste. Sofern die Setzung anderer Cookies (z.B. für
          Analyse-Funktionen) erfolgt, werden diese in dieser
          Datenschutzerklärung separat behandelt.
        </Typography>
        <Typography component="h2" variant="h5">
          Bestellformular
        </Typography>
        <Typography>
          Für die Verarbeitung der Bestellung werden personenbezogene Daten
          gespeichert, welche durch das Bestellformular
          von DAILYORGANIZED.COM erfasst werden. Im Rahmen des Bestellprozesses
          wird der Name und Vorname zur Identifikation des Kunden durch den
          Händler benötigt. Die Angabe einer E-Mail-Adresse ist zwingend
          notwendig für die Bestätigung bzw. Ablehnung der Bestellanfrage, sowie
          zur möglichen Kontaktaufnahme durch den Händler. Eine Telefonnummer
          kann seitens des Kunden optional angegeben werden und dient in diesem
          Fall als weitere Möglichkeit für die Kontaktaufnahme. Mit Ausnahme des
          Vor- und Nachnamens, sind diese angegebenen persönlichen Daten nicht
          für den Mitarbeiter*In des Händlers einsehbar. Mit jeder Bestellung
          wird darüber hinaus das Abholdatum sowie die bestellten Produkte (in
          gewünschter Menge) erfasst und verarbeitet.
        </Typography>
      </Stack>
    </Container>
  );
}
