import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useEffect, useState, useCallback } from "react";
import {
  addProduct,
  deleteProducts,
  getProducts,
  updateProduct,
  updateProducts,
} from "../../../../firebase";
import { useAuth } from "../../../context/AuthContext";
import { Switch } from "@mui/material";
import ProductManagerToolbar from "./ProductManagerToolbar";
import { useConfig } from "../../../context/ConfigContext";

export default function ProductManager() {
  const config = useConfig();
  const { currentUser } = useAuth();
  const [rows, setRows] = useState([]);
  const [productIsSelected, setProductIsSelected] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const columns = [
    {
      field: "name",
      headerName: "Produkt",
      flex: 0.5,
      editable: true,
      hideable: false,
    },
    {
      field: "info",
      headerName: "Zusätzliche Infos",
      flex: 0.5,
      editable: true,
      sortable: false,
      hideable: false,
    },
    {
      field: "price",
      headerName: "Preis",
      flex: 0.5,
      editable: true,
      type: "number",
      hideable: false,
    },
    {
      field: "active",
      headerName: "Aktiviert",
      type: "boolean",
      sortable: false,
      hideable: false,
      editable: false,
      renderCell: (params) => {
        return (
          <Switch
            disabled={
              params.row.name === "" ||
              (config.showPrices &&
                (params.row.price === 0 || params.row.price === null))
            }
            checked={params.row.active}
            onChange={(event) => {
              if (
                !(
                  config.showPrices &&
                  (params.row.price === 0 || params.row.price === null)
                )
              ) {
                updateProduct(currentUser.uid, params.row.id, {
                  active: event.target.checked,
                });
              }
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    console.log(config);
    const unsubscribe = getProducts(currentUser.uid, (data) => {
      setRows(data);
    });
    return () => unsubscribe();
  }, []);

  const apiRef = useGridApiRef();

  const handleCellClick = useCallback(
    (params) => {
      apiRef.current.isCellEditable(params) &&
        apiRef.current.setCellMode(params.id, params.field, "edit");
    },
    [apiRef]
  );

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      "cellModeChange",
      (params, event) => {
        event.defaultMuiPrevented = true;
      },
      { isFirst: true }
    );
  }, []);

  const handleCellEditCommit = (data) => {
    console.log(data);
    const newData = {};
    newData[data.field] = data.value;
    updateProduct(currentUser.uid, data.id, newData);
  };

  const handleSelection = (selection) => {
    setSelectedProducts(selection);
    if (selection.length === 0) {
      setProductIsSelected(false);
    } else {
      setProductIsSelected(true);
    }
  };

  const handleRemove = () => deleteProducts(currentUser.uid, selectedProducts);
  const handleAdd = () => addProduct(currentUser.uid);
  const handleActivate = () =>
    updateProducts(currentUser.uid, selectedProducts, { active: true });
  const handleDeactivate = () =>
    updateProducts(currentUser.uid, selectedProducts, { active: false });

  return (
    <div
      style={{
        height: "calc(100vh - 120px)",
        minHeight: "100%",
        width: "100%",
      }}
    >
      <DataGridPro
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
        apiRef={apiRef}
        sortingOrder={["desc", "asc"]}
        sx={{ borderColor: "primary.main" }}
        components={{ Toolbar: ProductManagerToolbar }}
        componentsProps={{
          toolbar: {
            selected: productIsSelected,
            onRemove: handleRemove,
            onAdd: handleAdd,
            onActivate: handleActivate,
            onDeactivate: handleDeactivate,
          },
        }}
        rows={rows}
        columns={columns}
        onCellClick={handleCellClick}
        onCellEditCommit={handleCellEditCommit}
        onSelectionModelChange={handleSelection}
        checkboxSelection
        disableSelectionOnClick
        disableColumnReorder
        disableColumnPinning
        disableColumnSelector
      />
    </div>
  );
}
