import { Alert, Grid, TextField } from "@mui/material";
export default function ContactDataGridItems({
  contactData,
  handleChange,
  disabled,
  showAlert,
}) {
  return (
    <>
      <Grid item md={6} xs={12}>
        <TextField
          name="fname"
          label="Vorname"
          fullWidth
          value={contactData.fname}
          required
          onChange={handleChange}
          disabled={disabled}
          variant="standard"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          name="lname"
          label="Nachname"
          fullWidth
          value={contactData.lname}
          required
          onChange={handleChange}
          disabled={disabled}
          variant="standard"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          name="email"
          label="Email-Adresse"
          fullWidth
          type="email"
          value={contactData.email}
          onChange={handleChange}
          disabled={disabled}
          variant="standard"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          name="emailConfirm"
          label="Email bestätigen"
          fullWidth
          type="email"
          value={contactData.emailConfirm}
          onChange={handleChange}
          disabled={disabled}
          variant="standard"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        {showAlert && (
          <Alert severity="error">Email-Adressen stimmen nicht überein</Alert>
        )}
      </Grid>
    </>
  );
}
