import React from "react";
import {TableCell} from "@mui/material";

import {styled} from "@mui/system";

const StyledTableCell = styled(TableCell)(({theme}) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.white,
  fontWeight: "bold",
  fontSize: 16,

  // hide last border
}));

export default StyledTableCell;
