import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import TimePicker from "@mui/lab/TimePicker";
import CenteredCircularProgress from "../../CenteredCircularProgress";

export default function Weekday({
  label,
  edit,
  value,
  index,
  setOpeningHours,
}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  function processDate(date) {
    if (date instanceof Date) return date;
    return date !== null && date.toDate();
  }

  const handleChangeOpen = (event) =>
    setOpeningHours((prev) => {
      const newData = [...prev];
      newData[index] = { ...newData[index], open: event.target.checked };
      return newData;
    });

  const handleChangeStart = (newValue) =>
    setOpeningHours((prev) => {
      const newData = [...prev];
      newData[index] = { ...newData[index], start: newValue };
      return newData;
    });

  const handleChangeEnd = (newValue) =>
    setOpeningHours((prev) => {
      const newData = [...prev];
      newData[index] = { ...newData[index], end: newValue };
      return newData;
    });

  return (
    <>
      {loading ? (
        <CenteredCircularProgress />
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  name="open"
                  checked={value.open}
                  onChange={handleChangeOpen}
                  disabled={!edit}
                />
              }
              label={label}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TimePicker
              name="start"
              label="Von"
              value={processDate(value.start)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              disabled={!edit}
              onChange={handleChangeStart}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TimePicker
              name="end"
              label="Bis"
              value={processDate(value.end)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              disabled={!edit}
              onChange={handleChangeEnd}
            />
          </Grid>
        </>
      )}
    </>
  );
}

Weekday.proTypes = {
  label: PropTypes.string,
  value: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};
