import React from "react";
import { Route, Navigate } from "react-router";
import { useAuth } from "../context/AuthContext";
import ConfigProvider from "../context/ConfigContext";

export default function PrivateRoute({ component: Component }) {
  const { currentUser } = useAuth();

  return currentUser ? (
    <ConfigProvider uid={currentUser.uid}>
      <Component />
    </ConfigProvider>
  ) : (
    <Navigate to="/login" />
  );
}
