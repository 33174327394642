import { useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { updateUserConfig } from "../../../firebase";

export default function ConfigSwitch({
  name,
  label,
  defaultChecked,
  checked,
  onChange,
  disabled,
}) {
  return (
    <FormControlLabel
      control={
        <Switch
          name={name}
          checked={checked}
          onChange={onChange}
          color="secondary"
        />
      }
      label={label}
      labelPlacement="start"
      sx={{ justifyContent: "space-between", ml: 0 }}
      disabled={disabled}
    />
  );
}
