import { useState, useEffect } from "react";
import DOAppBar from "../../layout/DOAppBar";
import {
  Container,
  Card,
  Typography,
  Grid,
  Stack,
  Tooltip,
  IconButton,
  Box,
  useMediaQuery,
} from "@mui/material";
import OpeningHoursCheckboxes from "./OpeninghoursCheckboxes";
// import ConfirmationMessage from "./ConfirmationMessage";
import VacationPicker from "./VacationPicker";
import Configuration from "./Configuration";
import ConfigProvider, { useConfig } from "../../context/ConfigContext";
import OrderDeadline from "./OrderDeadline";
import { GridOn } from "@mui/icons-material";
import { display } from "@mui/system";
import Branches from "./Branches";
import { useTheme } from "@mui/styles";

const spacing = "30px";

export default function Settings() {
  const theme = useTheme();

  return (
    <>
      <DOAppBar noSideBar />
      <Container sx={{ mt: "64px", p: 5 }}>
        <Stack gap={spacing}>
          <Box>
            <OpeningHoursCheckboxes />
          </Box>
          <Grid container spacing={spacing}>
            <Grid item xs={12} lg={8}>
              <Configuration />
            </Grid>
            <Grid item xs={12} lg={4}>
              {/* <ConfirmationMessage /> */}
              <OrderDeadline />
            </Grid>
          </Grid>
          {/* 
          <Box>
            <Branches />
          </Box> */}
        </Stack>
      </Container>
    </>
  );
}
