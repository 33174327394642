import React, { useRef, useState } from "react";
import logo from "../../assets/svg/logo.svg";
import loginGraphic from "../../assets/svg/loginGraphic.svg";
import {
  TextField,
  Box,
  Typography,
  Button,
  Alert,
  Card,
  Grid,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();

  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigator = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      const response = await login(
        emailRef.current.value,
        passwordRef.current.value
      );
      console.log(response.user.uid);
      navigator.push(`/dashboard`);
    } catch (err) {
      console.log(err.code);
      switch (err.code) {
        case "auth/wrong-password":
          setError("Falsches Passwort");
          break;
        case "auth/user-not-found":
          setError("Benutzer wurde nicht gefunden");
          break;
        case "auth/too-many-requests":
          setError("Zu viele Anmeldeversuche");
          break;
        default:
          setError("Anmeldung fehlgeschlagen");
          break;
      }
      setLoading(false);
    }
  }

  const OrderLink = (props) => (
    <Link to="/order/cf56fdef-9901-42f1-9b38-77efd4e2d7ab" {...props} />
  );

  return (
    <Grid
      container
      alignItems="center"
      sx={{ height: "100%", overflow: "hidden" }}
    >
      <Grid
        item
        xl={4}
        lg={5}
        md={6}
        sm={12}
        xs={12}
        sx={{ backgroundColor: "primary.main", height: "100%" }}
        container
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Card raised sx={{ padding: 4, margin: 4, maxWidth: "400px" }}>
            <Box sx={{ textAlign: "center" }}>
              <img
                src={logo}
                alt="Logo"
                style={{ height: "50px", padding: "10px" }}
              />
              <Typography component="h1" variant="h1">
                Anmelden
              </Typography>
            </Box>
            {error && (
              <Alert severity="error" sx={{ margin: "1rem 0" }}>
                {error}
              </Alert>
            )}
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email-Addresse"
                name="email"
                autoComplete="email"
                inputRef={emailRef}
                type="email"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="Passwort"
                name="password"
                autoComplete="current-password"
                inputRef={passwordRef}
                type="password"
              />
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{ marginTop: "1rem" }}
                fullWidth
                disabled={loading}
              >
                Anmelden
              </Button>
            </form>
          </Card>
          <Card raised sx={{ padding: 4, margin: 4, maxWidth: "400px" }}>
            <Button variant="outlined" fullWidth component={OrderLink}>
              Zum Bestellformular
            </Button>
          </Card>
          {/* <Typography
            component="p"
            variant="body2"
            mt={2}
            align="center"
            color="white"
          >
            Sie besitzen noch keinen Account?
          </Typography>
          <Typography variant="body2" align="center">
            <Link to="/signup" style={{ color: "#F7A96E" }}>
              Registrieren
            </Link>
          </Typography> */}
        </Grid>
      </Grid>
      <Grid
        item
        xl={8}
        lg={7}
        md={6}
        sx={{ backgroundColor: "secondary.lighter", height: "100%" }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <img
            src={loginGraphic}
            alt="background"
            style={{
              minWidth: "60vw",
              maxWidth: "80vw",
              paddingBottom: "4rem",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
