import { Stack, TextField } from "@mui/material";
import capitalize from "@mui/utils/capitalize";

export default function Step1({ contactData, setContactData }) {
  function handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "fname")
      return setContactData((prev) => ({ ...prev, fname: capitalize(value) }));
    if (name === "lname")
      return setContactData((prev) => ({ ...prev, lname: capitalize(value) }));
    if (name === "email")
      return setContactData((prev) => ({ ...prev, email: value }));
    if (name === "emailConfirm")
      return setContactData((prev) => ({ ...prev, emailConfirm: value }));
  }
  return (
    <Stack spacing={2}>
      <TextField
        name="fname"
        label="Vorname"
        fullWidth
        value={contactData.fname}
        required
        onChange={handleChange}
      />
      <TextField
        name="lname"
        label="Nachname"
        fullWidth
        value={contactData.lname}
        required
        onChange={handleChange}
      />
      <TextField
        name="email"
        label="Email-Adresse"
        fullWidth
        type="email"
        value={contactData.email}
        required
        onChange={handleChange}
      />
      <TextField
        name="emailConfirm"
        label="Email bestätigen"
        fullWidth
        type="email"
        value={contactData.emailConfirm}
        required
        onChange={handleChange}
      />
    </Stack>
  );
}
