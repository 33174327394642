import React from "react";
import { makeStyles } from "@mui/styles";
import { Line } from "react-chartjs-2";

const useStyles = makeStyles((theme) => ({
  divStyle: {
    position: "relative",
    width: "calc(100vw - 5rem)",
    height: "150px",
    [theme.breakpoints.up("sm")]: {
      width:"inherit", 
      height: "200px",
    },
    [theme.breakpoints.up("lg")]: { 
      height: "300px",
      width: "100%",
    },
  },
}));

export default function WeeklyTotalChart() {
  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(300, 0, 300, 400);
    gradient.addColorStop(0, "rgba(239, 134, 77, 1)");
    gradient.addColorStop(1, "rgba(239, 134, 77, 0.8)");

    return {
      labels: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Deztember",
      ],
      datasets: [
        {
          label: "Anzahl Bestellungen",
          data: [50, 30, 40, 50, 120, 66, 100, 80, 90, 55, 77, 150],
          fill: "start",
          cubicInterpolationMode: "monotone",
          backgroundColor: [gradient],
        },
      ],
    };
  };

  var options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const classes = useStyles();

  return (
    <div className={classes.divStyle}>
      <Line data={data} options={options} />
    </div>
  );
}
