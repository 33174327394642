import { Doughnut } from "react-chartjs-2";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  divStyle: {
    position: "relative",
    width: "calc(100vw - 5rem)",
    height: "150px",
    [theme.breakpoints.up("sm")]: {
      width:"inherit", 
      height: "200px",
    },
    [theme.breakpoints.up("lg")]: { 
      height: "250px",
      width: "inherit",
    },
  },
}));

export default function BranchesDoughnut({ branches, colorscheme }) {
  const data = () => {
    return {
      labels: branches.map((branch) => branch.name),
      datasets: [
        {
          data: branches.map((branch) => branch.summary),
          label: "Anzahl Betsellungen",
          borderRadius: 7,
          backgroundColor: colorscheme,
        },
      ],
    };
  };
  var options = {
    resposive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "left",
      },
    },
  };

  const classes = useStyles();

  return (
    <div className={classes.divStyle}>
      <Doughnut data={data} options={options} />
    </div>
  );
}
