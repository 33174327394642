// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  getDocs,
  writeBatch,
  where,
  collection,
  query,
  addDoc,
  setDoc,
  getDoc,
  doc,
  updateDoc,
  onSnapshot,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { v4 as uuidv4 } from "uuid";

import { defaultOpeningHours } from "./util/globalConstants";
import { productHasPrice } from "./util/tools";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// import { getAuth, connectAuthEmulator } from "firebase/auth";

// const auth = getAuth();
// connectAuthEmulator(auth, "http://localhost:9099");

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCba5c78zNEIE4qZb5s8EvujBaqdXeIFqM",
  authDomain: "dailyorganized-test.firebaseapp.com",
  projectId: "dailyorganized-test",
  storageBucket: "dailyorganized-test.appspot.com",
  messagingSenderId: "932067564743",
  appId: "1:932067564743:web:a765574e7214ec9e30f2e2",
  measurementId: "G-J741FHRBW3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
// connectFirestoreEmulator(db, "localhost", 8080);
export default app;
export const functions = getFunctions(app);

export async function getUserById(uid, callback) {
  const user = await getDoc(doc(db, "users", uid));
  callback(user);
}

// Takes the url after the /order route and passes the associated user data to a callback
export async function getUserByURL(url, callback) {
  const q = query(collection(db, "users"), where("url", "==", url));
  let results = [];
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      results.push({ id: doc.id, data: doc.data() });
    });
  } catch (err) {
    console.error(err);
  }
  callback({ user: results[0] });
}

export function getProducts(userId, callback, condition) {
  const q = query(collection(db, `users/${userId}/products`));
  return onSnapshot(q, (querySnapshot) => {
    const products = querySnapshot.docs.map((product) => ({
      ...product.data(),
      id: product.id,
    }));
    callback(products);
  });
}

export async function updateProduct(userId, productId, newData) {
  if (!productHasPrice(newData)) newData.active = false;
  const docRef = doc(db, `users/${userId}/products`, productId);
  await updateDoc(docRef, newData);
}

export async function updateProducts(userId, products, newData) {
  const batch = writeBatch(db);
  products.forEach((productId) => {
    const docRef = doc(db, `users/${userId}/products`, productId);
    batch.update(docRef, { ...newData });
  });
  await batch.commit();
}

export async function deleteProducts(userId, products) {
  const batch = writeBatch(db);
  products.forEach((productId) => {
    const docRef = doc(db, `users/${userId}/products`, productId);
    batch.delete(docRef);
  });
  await batch.commit();
}

export async function addProduct(userId) {
  await addDoc(collection(db, `users/${userId}/products`), {
    name: "",
    info: "",
    price: 0,
    active: false,
  });
}

export function getUserConfig(userId, callback) {
  const docRef = doc(db, `users/${userId}/userData`, "config");
  return onSnapshot(docRef, (doc) => {
    callback(doc.data());
  });
}

export async function updateUserConfig(userId, newData) {
  const docRef = doc(db, `users/${userId}/userData`, "config");
  await updateDoc(docRef, newData);
}

// export async function getInformation(userId, callback) {
//   const docRef = doc(db, `users/${userId}/userData`, "information");
//   const docSnap = await getDoc(docRef);
//   const data = docSnap.data();
//   callback(data);
// }

// export async function updateInformation(userId, newData) {
//   const docRef = doc(db, `users/${userId}/userData`, "information");
//   await updateDoc(docRef, newData);
// }

export function getBranches(uid, callback, all) {
  const q =
    all === "all"
      ? query(collection(db, `users/${uid}/branches`))
      : query(
          collection(db, `users/${uid}/branches`),
          where("active", "==", true)
        );
  return onSnapshot(q, (querySnapshot) => {
    const branches = querySnapshot.docs.map((branch) => ({
      id: branch.id,
      ...branch.data(),
    }));
    callback(branches);
  });
}

export async function updateBranch(userId, branchId, newData) {
  const docRef = doc(db, `users/${userId}/branches`, branchId);
  await updateDoc(docRef, newData);
}

export async function updateBranches(userId, branches, newData) {
  const batch = writeBatch(db);
  branches.forEach((branchId) => {
    const docRef = doc(db, `users/${userId}/branches`, branchId);
    batch.update(docRef, { ...newData });
  });
  await batch.commit();
}

export async function deleteBranches(userId, branches) {
  const batch = writeBatch(db);
  branches.forEach((branchId) => {
    const docRef = doc(db, `users/${userId}/branches`, branchId);
    batch.delete(docRef);
  });
  await batch.commit();
}

export async function addBranch(userId, callback) {
  await addDoc(collection(db, `users/${userId}/branches`), {
    name: "",
    address: "",
    active: false,
  });
}

export async function addOrder(
  userId,
  contactData,
  dateToCollect,
  selectedBranch,
  orderData,
  callback,
  options
) {
  delete contactData.emailConfirm;
  const order = {
    accepted: options?.fromDashboard ? true : false,
    active: true,
    checked: false,
    contactData,
    selectedBranch: doc(db, `users/${userId}/branches`, selectedBranch),
    dateToCollect,
    dateOrdered: new Date(),
  };

  typeof orderData === "string"
    ? (order["orderText"] = orderData)
    : (order["products"] = orderData);

  const orderRef = await addDoc(
    collection(
      db,
      `users/${userId}/${
        typeof orderData === "string" ? "customOrders" : "orders"
      }`
    ),
    order
  );
  callback();
}

export async function addProductToProductList(userId = uuidv4(), product = {}) {
  await addDoc(collection(db, `users/${userId}/products`), product);
}

export function updateOrderActiveStatus(
  userId,
  orderId,
  collection,
  declineMessage
) {
  const orderRef = doc(db, `users/${userId}/${collection}`, orderId);
  const newData = {
    active: false,
  };
  if (declineMessage) newData.declineMessage = declineMessage;
  updateDoc(orderRef, newData);
}

export async function updateOrderAcceptStatus(userId, orderId, collection) {
  const orderRef = doc(db, `users/${userId}/${collection}`, orderId);
  await updateDoc(orderRef, {
    accepted: true,
  });
  const order = await getDoc(orderRef);
  console.log(order);
}

export function toggleOrderCheckbox(userId, orderId, collection, checked) {
  const orderRef = doc(db, `users/${userId}/${collection}`, orderId);
  updateDoc(orderRef, {
    checked: !checked,
  });
}

export async function getInformation(userId, callback) {
  const docRef = doc(db, `users/${userId}/userData/information`);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  function allObjectsInArrayHaveStartAndEndFields(array) {
    return array.every((obj) => {
      return obj.start && obj.end;
    });
  }
  if (
    !data.openingHours ||
    Object.keys(data).length === 0 ||
    !allObjectsInArrayHaveStartAndEndFields(data.openingHours)
  ) {
    await setDoc(docRef, defaultOpeningHours);
    return callback(defaultOpeningHours);
  }
  return callback(data);
}

export async function updateInformation(userId, newData) {
  console.log(newData);
  const docRef = doc(db, `users/${userId}/userData/information`);
  await updateDoc(docRef, newData);
}
