import PropTypes from "prop-types";
import {
  Stack,
  TextField,
  Autocomplete,
  MenuItem,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Fade,
  Paper,
  Button,
} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useState } from "react";
import { useEffect } from "react";
import CenteredCircularProgress from "../CenteredCircularProgress";
import { getProducts } from "../../firebase";

export default function ProductSelect({
  userId,
  orderedProducts,
  setOrderedProducts,
  index,
  config,
  disabled,
}) {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const unsubscribe = getProducts(userId, (dbProducts) => {
      const filteredDbProducts = dbProducts.filter((product) => product.active); //TODO: filter already ordered products
      setProducts(filteredDbProducts);
      setOptions(
        filteredDbProducts.map((product) => {
          const price = !isNaN(product.price) ? product.price : "";
          const option = {
            id: product.id,
            name: product.name,
          };
          option.price = price;
          option.info = product.info ? product.info : "";
          return option;
        })
      );
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  function removeProduct(index) {
    setOrderedProducts((prev) => {
      return prev.filter((item) => {
        const itemIndex = prev.indexOf(item);
        return itemIndex !== index && item;
      });
    });
  }

  options.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
  });

  return (
    <Fade in timeout={500}>
      <Paper variant="outlined" sx={{ px: 3, py: 2 }}>
        {loading ? (
          <CenteredCircularProgress />
        ) : (
          <>
            <Stack direction="column" spacing={2}>
              {products.length ? ( //check if customOrder
                <Autocomplete
                  autoComplete
                  autoSelect
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === value.id;
                  }}
                  disableClearable
                  value={
                    orderedProducts[index].id ? orderedProducts[index] : null
                  }
                  onChange={(event, newValue) => {
                    setOrderedProducts((prev) => {
                      console.log(newValue);
                      newValue.count = prev[index].count;
                      console.log(newValue);
                      delete newValue.info;
                      prev[index] = newValue;
                      return [...prev];
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Produkt"
                      helperText={
                        config.showPrices &&
                        orderedProducts[index].price &&
                        `1 Stück: ${orderedProducts[index].price} € `
                      }
                      sx={{ mb: 1.5 }}
                      required
                      variant="standard"
                    />
                  )}
                  renderOption={(params, option) => {
                    return (
                      <Fade in {...params}>
                        <MenuItem>
                          <ListItemText>{option.name}</ListItemText>
                          <Stack direction="row" spacing={3}>
                            {option.info && (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                ({option.info})
                              </Typography>
                            )}
                            {config.showPrices && (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {option.price} €
                              </Typography>
                            )}
                          </Stack>
                        </MenuItem>
                      </Fade>
                    );
                  }}
                  options={options}
                  disabled={disabled}
                />
              ) : (
                <TextField
                  label="Produkt"
                  onChange={(event) => {
                    setOrderedProducts((prev) => {
                      prev[index] = event.target.value;
                      return [...prev];
                    });
                  }}
                  disabled={disabled}
                />
              )}

              <TextField
                required
                type="number"
                inputProps={{ min: 1 }}
                sx={{ width: "50%" }}
                label="Anzahl"
                value={orderedProducts[index].count}
                onChange={(event) => {
                  setOrderedProducts((prev) => {
                    if (event.target.value > 0)
                      prev[index].count = parseInt(event.target.value);
                    if (event.target.value == "")
                      prev[index].count = parseInt(event.target.value);
                    console.log(prev[index]);
                    return [...prev];
                  });
                }}
                disabled={disabled}
                variant="standard"
              />
              {orderedProducts[index] && config.showPrices && (
                <ListItem>
                  <ListItemIcon>
                    <LocalOfferIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${(
                      orderedProducts[index].price *
                      orderedProducts[index].count
                    ).toFixed(2)} €`}
                  />
                </ListItem>
              )}
            </Stack>
            {orderedProducts.length > 1 && (
              <Button
                sx={{ mt: 2 }}
                onClick={() => removeProduct(index)}
                variant="outlined"
                color="danger"
              >
                Produkt entfernen
              </Button>
            )}
          </>
        )}
      </Paper>
    </Fade>
  );
}

ProductSelect.propTypes = {
  userId: PropTypes.string.isRequired,
  orderedProducts: PropTypes.array.isRequired,
  setOrderedProducts: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
};
