import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import { DataGrid, deDE } from "@mui/x-data-grid";
import { deDE as coreDeDE } from "@mui/material/locale";

let theme = createTheme(
  {
    shape: { borderRadius: 15 },
    palette: {
      mode: "light",
      primary: {
        main: "#152948", //#00A2D0
        lighter: "#E1E8EF",
      },
      secondary: {
        main: "#EF864D", //#FC6A3D
        contrastText: "#000",
      },
      common: {
        black: "#000",
        white: "#fff",
      },
      danger: {
        main: "#f44336",
      },
      tonalOffset: "0.3",
    },
    typography: {
      h1: {
        fontSize: "3.25rem",
        fontWeight: "400",
      },
      h2: {
        fontSize: "2.875rem",
      },
      h3: {
        fontSize: "2.562rem",
      },
      h4: {
        fontSize: "2.312rem",
      },
      h5: {
        fontSize: "2rem",
      },
      h6: {
        fontSize: "1.8rem",
      },
      body1: {
        fontFamily: "'Libre Franklin', sans-serif",
      },
      body2: {
        fontFamily: "'Libre Franklin', sans-serif",
      },
      button: {
        fontFamily: "'Libre Franklin', sans-serif",
      },
      caption: {
        fontFamily: "'Libre Franklin', sans-serif",
      },
      overline: {
        fontFamily: "'Libre Franklin', sans-serif",
      },
    },
  },
  deDE,
  coreDeDE
);

theme = responsiveFontSizes(theme, {
  factor: 2,
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
});

export default function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
