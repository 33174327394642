import React, { useContext, useState, useEffect } from "react";
import { addSuperUser } from "../../firebase";

import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import CenteredCircularProgress from "../CenteredCircularProgress";

const AuthContext = React.createContext();
const auth = getAuth();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  // function signup(uname, email, password, productList, branches, config) {
  //   return addSuperUser({ uname, productList, branches, config }, () => {
  //     return createUserWithEmailAndPassword(auth, email, password);
  //   });
  // }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    // signup,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
