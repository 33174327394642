import { useEffect, useState } from "react";
import {
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Stack,
  Box,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import SettingsCard from "./SettingsCard";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import deLocale from "date-fns/locale/de";
import TimePicker from "@mui/lab/TimePicker";
import { getInformation, updateInformation } from "../../../firebase";
import { useAuth } from "../../context/AuthContext";
import CenteredCircularProgress from "../../CenteredCircularProgress";

export default function OrderDeadline() {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const [time, setTime] = useState();
  const [dbTime, setDbTime] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [saving, setSaving] = useState();

  useEffect(() => {
    getInformation(currentUser.uid, (data) => {
      if (!data.orderDeadline) {
        const initialTime = new Date("2001-01-01:18:00:00");
        setTime(initialTime);
        setDbTime(initialTime);
        updateInformation(currentUser.uid, { orderDeadline: initialTime });
      } else {
        setDbTime(data.orderDeadline.toDate());
        setTime(data.orderDeadline.toDate());
      }
      setLoading(false);
    });
  }, []);

  const handleChange = (newValue) => {
    const newValueHours = newValue.getHours();
    const newValueMinutes = newValue.getMinutes();
    const dbTimeHours = dbTime.getHours();
    const dbTimeMinutes = dbTime.getMinutes();

    if (newValueHours === dbTimeHours && newValueMinutes === dbTimeMinutes) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
    setTime(newValue);
  };

  const handleSave = async () => {
    setSaving(true);
    if (!isNaN(time)) {
      setButtonDisabled(true);
      await updateInformation(currentUser.uid, { orderDeadline: time });
      setDbTime(time);
    }
    setSaving(false);
  };

  return (
    <SettingsCard>
      {loading ? (
        <CenteredCircularProgress />
      ) : (
        <>
          <Typography variant="h2" component="h2" mb={2}>
            Bestellschluss
            {/* <Tooltip title="Richtgabe für den Kunden, bis wann spätestens die Bestellung eingegangen sein muss, damit mit für den folgenden Werktag noch bestätigt und verarbeitet werden kann.">
              <IconButton>
                <HelpRoundedIcon />
              </IconButton>
            </Tooltip> */}
          </Typography>
          <Stack direction="column" spacing={3}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={deLocale}
            >
              <TimePicker
                value={time}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField {...params} disabled={saving} />
                )}
              />
            </LocalizationProvider>
            <LoadingButton
              variant="contained"
              onClick={handleSave}
              disabled={buttonDisabled}
              loading={saving}
            >
              Änderung Speichern
            </LoadingButton>
          </Stack>
        </>
      )}
    </SettingsCard>
  );
}
