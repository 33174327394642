import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Paper,
  Link,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

// Material Icons
import PersonIcon from "@mui/icons-material/Person";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import EventIcon from "@mui/icons-material/Event";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import EuroIcon from "@mui/icons-material/Euro";

export default function Step4({
  contactData,
  orderedProducts,
  selectedDay,
  config,
  isCustomOrder,
  customOrder,
  dataProtectionAccepted,
  setDataProtectionAccepted,
}) {
  selectedDay = new Date(selectedDay);
  let day = selectedDay.getDate();
  if (day < 10) day = `0${day}`;
  let month = selectedDay.getMonth() + 1;
  if (month < 10) month = `0${month}`;
  let year = selectedDay.getFullYear();
  let gesamtpreis = 0;
  orderedProducts.forEach((product) => {
    gesamtpreis += product.price * product.count;
  });

  return (
    <>
      <List sx={{ backgroundColor: "" }}>
        <ListItem>
          <Typography variant="h6" component="div">
            Kontaktdaten
          </Typography>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText
            primary={`${contactData.fname} ${contactData.lname}`}
            secondary="Name"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <AlternateEmailIcon />
          </ListItemIcon>
          <ListItemText primary={contactData.email} secondary="Email-Adresse" />
        </ListItem>
        <ListItem>
          <Typography variant="h6" component="div">
            Abholung
          </Typography>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <Typography letterSpacing={3}>{`${day}.${month}.${year}`}</Typography>
        </ListItem>
        <ListItem>
          <Typography variant="h6" component="div">
            Bestellung
          </Typography>
        </ListItem>
        {!isCustomOrder ? (
          orderedProducts.map((product, index) => (
            <ListItem key={`product${index}`}>
              <ListItemIcon>
                <AddShoppingCartIcon />
              </ListItemIcon>
              <ListItemText
                primary={product.name}
                secondary={config.showPrices && `1 Stück: ${product.price} €`}
              />
              <ListItemText
                primary={`× ${product.count}`}
                secondary={
                  config?.showPrices && `= ${product.count * product.price} €`
                }
              />
            </ListItem>
          ))
        ) : (
          <ListItem>
            <Paper sx={{ p: 2, w: "100%" }} variant="outlined">
              <Typography sx={{ w: "100%" }}>{customOrder}</Typography>
            </Paper>
          </ListItem>
        )}
        {config?.showPrices && !isCustomOrder && (
          <>
            <ListItem>
              <Typography variant="h6" component="div">
                Gesamtpreis
              </Typography>
            </ListItem>
            <ListItem alignItems="center">
              <ListItemIcon>
                <Typography variant="h4" component="div">
                  {gesamtpreis.toString()}
                </Typography>
                <EuroIcon />
              </ListItemIcon>
            </ListItem>
          </>
        )}
      </List>

      <FormGroup sx={{ pb: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={dataProtectionAccepted}
              onChange={(e) => setDataProtectionAccepted(e.target.checked)}
            />
          }
          label={
            <>
              <Typography variant="body2">
                Ich erkläre mich gemäß der{" "}
                <Link
                  href="/datenschutz"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Datenschutzerklärung
                </Link>{" "}
                damit einverstanden, dass meine Daten zur Bearbeitung der
                Anfrage verwendet werden dürfen.
              </Typography>
            </>
          }
        ></FormControlLabel>
      </FormGroup>
    </>
  );
}
