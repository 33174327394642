import { Typography, Paper, Grid, } from "@mui/material";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { styled } from "@mui/material/styles";
import "@mui/styles/useTheme";

const BarWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  height: "130px",
  width: "calc(100vw - 5rem)",
  [theme.breakpoints.up("sm")]: {
    width: "inherit",
  },
  [theme.breakpoints.up("lg")]: {
    height: "170px",
    width: "inherit",
  },
}));

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        color: "#ffffff",
      },
      grid: {
        color: "rgba(255, 255, 255, 0.2)",
        borderColor: "rgba(255, 255, 255, 0.2)",
      },
      axis: {
        color: "#ffffff",
      },
    },
  },
};

function BranchesDashboardItem({ branches, colorscheme }) {
  return (
    <Grid container spacing={3} m={0}>
      {branches.map((branch) => (
        <Grid item xs={12} sm={6} md={4} lg={4} key={branch.id}>
          <Paper
            variant="outlined"
            elevation={0}
            sx={{
              padding: "1rem 1.5rem 1.5rem 1.5rem",
              //backgroundColor: "linear-gradient(22deg, ",
              borderColor: "primary.main",
            }}
          >
            <Typography variant="h6" component="h2" color="primary">
              {branch.name}
            </Typography>
            <BarWrapper>
              <Bar
                data={{
                  labels: [
                    "Montag",
                    "Dienstag",
                    "Mittwoch",
                    "Donnerstag",
                    "Freitag",
                    "Samstag",
                    "Sonntag",
                  ],
                  datasets: [
                    {
                      label: "Anzahl Bestellungen",
                      data: branch.data,
                      fill: "start",
                      backgroundColor: [colorscheme[branch.id]],
                    },
                  ],
                }}
                options={options}
              />
            </BarWrapper>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

BranchesDashboardItem.propTypes = {
  branches: PropTypes.array.isRequired,
};

export default BranchesDashboardItem;
