export const shiftArray = (array) => {
  const arrayClone = [...array];
  const firstElement = arrayClone.shift();
  arrayClone.push(firstElement);
  return arrayClone;
};

export const unShiftArray = (array) => {
  const arrayClone = [...array];
  const lastElement = arrayClone.pop();
  arrayClone.unshift(lastElement);
  return arrayClone;
};

export const incrementDate = (date, incrementByXDays) => {
  const incrementSize = incrementByXDays || 1;
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  date.setDate(day + incrementSize);
  if (date.getDate() < day) date.setMonth(month + 1);
  if (date.getMonth() < month) date.setFullYear(year + 1);
  return date;
};

export function productHasPrice(data) {
  if (data.price === null) return false;
  if (data.price === "") return false;
  if (data.price === 0) return false;
  return true;
}
