export const spacing = 3;

export const defaultOpeningHours = {
  openingHours: [
    {
      name: "sunday",
      label: "Sonntag",
      open: false,
      start: new Date("1970-01-01T06:00:00"),
      end: new Date("1970-01-01T18:00:00"),
    },
    {
      name: "monday",
      label: "Montag",
      open: false,
      start: new Date("1970-01-01T06:00:00"),
      end: new Date("1970-01-01T18:00:00"),
    },
    {
      name: "tuesday",
      label: "Dienstag",
      open: false,
      start: new Date("1970-01-01T06:00:00"),
      end: new Date("1970-01-01T18:00:00"),
    },
    {
      name: "wednesday",
      label: "Mittwoch",
      open: false,
      start: new Date("1970-01-01T06:00:00"),
      end: new Date("1970-01-01T18:00:00"),
    },
    {
      name: "thursday",
      label: "Donnerstag",
      open: false,
      start: new Date("1970-01-01T06:00:00"),
      end: new Date("1970-01-01T18:00:00"),
    },
    {
      name: "friday",
      label: "Freitag",
      open: false,
      start: new Date("1970-01-01T06:00:00"),
      end: new Date("1970-01-01T18:00:00"),
    },
    {
      name: "saturday",
      label: "Samstag",
      open: false,
      start: new Date("1970-01-01T06:00:00"),
      end: new Date("1970-01-01T18:00:00"),
    },
  ],
};
