import { Modal, Paper, Typography, Button, Stack, Box } from "@mui/material";
import { useTheme } from "@mui/styles";

function Backdrop() {
  return (
    <div
      style={{
        zIndex: -1,
        position: "fixed",
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "rgba(200, 200, 200, 0.5)",
      }}
    />
  );
}

export default function ConfirmModal({
  open,
  setOpen,
  onConfirm,
  title,
  content,
}) {
  const theme = useTheme();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    bgcolor: "background.paper",
    borderRadius: `${theme.shape.borderRadius}px`,
    outline: "none",
    p: 4,
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-content"
      BackdropComponent={Backdrop}
    >
      <Paper sx={style} variant="outlined">
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Box id="modal-modal-content" sx={{ mt: 2 }}>
          {content}
        </Box>
        <Stack
          direction="row"
          sx={{ justifyContent: "end", mt: 3 }}
          spacing={3}
        >
          <Button
            onClick={handleCancel}
            size="large"
            color="error"
            variant="outlined"
          >
            Abbrechen
          </Button>
          <Button onClick={onConfirm} size="large" variant="contained">
            Bestätigen
          </Button>
        </Stack>
      </Paper>
    </Modal>
  );
}
