import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import deLocale from "date-fns/locale/de";
import { Alert } from "@mui/material";
import CenteredCircularProgress from "../CenteredCircularProgress";
import { convertToMeridiem } from "@mui/lab/internal/pickers/time-utils";
import { incrementDate } from "../../util/tools";
import { getInformation } from "../../firebase";

export default function DateSelect({
  selectedDay,
  setSelectedDay,
  disabled,
  showAlert,
  userId,
}) {
  const [loading, setLoading] = useState(true);
  const tomorrow = incrementDate(new Date());
  let nextDateForOrders = new Date();
  const openingHours = useRef();
  const [nearestOrderInXDays, setNearestOrderInXDays] = useState();

  function getNextDayOpen(date, counter = 0) {
    const weekday = date.getDay();
    if (openingHours.current[weekday].open) {
      return date;
    } else {
      if (counter < 6) {
        return getNextDayOpen(incrementDate(date), counter + 1);
      } else return new Date();
    }
  }

  useEffect(() => {
    getInformation(userId, (information) => {
      if (information.orderDeadline !== null) {
        const now = new Date();
        const nowHours = now.getHours();
        const nowMinutes = now.getMinutes();
        const hours = information.orderDeadline.toDate().getHours();
        const minutes = information.orderDeadline.toDate().getMinutes();

        if (nowHours < hours) setNearestOrderInXDays(1);
        if (nowHours > hours) setNearestOrderInXDays(2);
        if (nowHours === hours)
          setNearestOrderInXDays(nowMinutes < minutes ? 1 : 2);
      } else {
        setNearestOrderInXDays(1);
      }
      getInformation(userId, (data) => {
        openingHours.current = data.openingHours;

        nextDateForOrders = getNextDayOpen(
          nearestOrderInXDays === 1 || !nearestOrderInXDays
            ? tomorrow
            : incrementDate(tomorrow, nearestOrderInXDays)
        );

        nextDateForOrders.setDate(getNextDayOpen(tomorrow).getDate());
        setSelectedDay(nextDateForOrders);
        setLoading(false);
      });
    });
  }, []);

  return (
    <>
      {loading ? (
        <TextField disabled />
      ) : (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
            <DatePicker
              shouldDisableDate={(date) => {
                switch (date.getDay()) {
                  case 0:
                    return !openingHours.current[0].open;
                  case 1:
                    return !openingHours.current[1].open;
                  case 2:
                    return !openingHours.current[2].open;
                  case 3:
                    return !openingHours.current[3].open;
                  case 4:
                    return !openingHours.current[4].open;
                  case 5:
                    return !openingHours.current[5].open;
                  case 6:
                    return !openingHours.current[6].open;
                }
              }}
              disabled={disabled}
              mask="__.__.____"
              minDate={nextDateForOrders}
              openTo="day"
              value={selectedDay}
              onChange={(newValue) => {
                setSelectedDay(newValue);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
          {showAlert && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Abholen an diesem Tag nicht möglich
            </Alert>
          )}
        </>
      )}
    </>
  );
}

DateSelect.propTypes = {
  selectedDay: PropTypes.object.isRequired,
  setSelectedDay: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showAlert: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};
