import { useState, useRef, useEffect } from "react";
import {
  Grid,
  Divider,
  Button,
  Fade,
  FormControlLabel,
  Switch,
  TextField,
  Alert,
} from "@mui/material";
import PageCard from "../PageCard";
import ProductSelect from "../../../../selects/ProductSelect";
import { useConfig } from "../../../../context/ConfigContext";
import { getProducts, addOrder } from "../../../../../firebase";
import { useAuth } from "../../../../context/AuthContext";
import ContactDataGridItems from "./ContactDataGridItems";
import { capitalize } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import DoneIcon from "@mui/icons-material/Done";
import DateSelect from "../../../../selects/DateSelect";
import SectionTitle from "./SectionTitle";

const fadeDuration = 500;
const spacing = 2;
const savingMinDuration = 1000;
const alertTimeout = 5000;

const emptyContactData = () => ({
  fname: "",
  lname: "",
  email: "",
  emailConfirm: "",
});
const testContactData = () => ({
  fname: "Max",
  lname: "Mustermann",
  email: "tim.schulz.rc@gmail.com",
  emailConfirm: "tim.schulz.rc@gmail.com",
});
const emptyProduct = () => ({ id: "", name: "", count: 1, price: null });
const emptyProductList = () => [emptyProduct()];

export default function AddOrder({ selectedBranch }) {
  const now = new Date();
  const tomorrow = new Date(now);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [contactData, setContactData] = useState(emptyContactData());
  const config = useConfig();
  const { currentUser } = useAuth();
  const userId = currentUser.uid;
  const [saving, setSaving] = useState(false);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [orderedProducts, setOrderedProducts] = useState(emptyProductList());
  const [customOrder, setCustomOrder] = useState("");
  const [isCustomOrder, setIsCustomOrder] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [submitButtonIcon, setSubmitButtonIcon] = useState(<SaveIcon />);
  const [showDateAlert, setShowDateAlert] = useState(false);
  const [showEmailAlert, setShowEmailAlert] = useState(false);
  const timeouts = [];

  useEffect(() => {
    process.env.NODE_ENV === "development" && setContactData(testContactData());
    return () => {
      for (var i = 0; i < timeouts.length; i++) {
        clearTimeout(timeouts[i]);
      }
    };
  }, []);

  useEffect(() => {
    setDisabled(saving);
  }, [saving]);

  function showOrderAddedSuccess() {
    setSubmitButtonIcon(<DoneIcon />);
    timeouts.push(
      setTimeout(() => {
        setSubmitButtonIcon(<SaveIcon />);
      }, savingMinDuration)
    );
  }

  function addProduct() {
    setOrderedProducts((prev) => [...prev, emptyProduct()]);
  }

  function hasProblem() {
    let hasProblem = false;
    if (contactData.email !== contactData.emailConfirm) {
      hasProblem = true;
      setShowEmailAlert(true);
      timeouts.push(
        setTimeout(() => {
          setShowEmailAlert(false);
        }, alertTimeout)
      );
    }

    return hasProblem;
  }

  function handleConfirmClick(event) {
    event.preventDefault();
    console.log(hasProblem());
    if (hasProblem()) return;
    setShowEmailAlert(false);
    setShowDateAlert(false);
    setSaving(true);
    setContactData(emptyContactData());
    setCustomOrder("");
    process.env.NODE_ENV === "development" && setContactData(testContactData());
    addOrder(
      userId,
      contactData,
      selectedDay,
      selectedBranch.id,
      isCustomOrder ? customOrder : orderedProducts,
      (err) => {
        !err
          ? timeouts.push(
              setTimeout(() => {
                setSaving(false);
                showOrderAddedSuccess();
              }, 2000)
            )
          : console.log(err);
        setOrderedProducts(emptyProductList());
      },
      { fromDashboard: true }
    );
  }

  function handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "fname")
      return setContactData((prev) => ({
        ...prev,
        fname: capitalize(value),
      }));
    if (name === "lname")
      return setContactData((prev) => ({
        ...prev,
        lname: capitalize(value),
      }));
    if (name === "email")
      return setContactData((prev) => ({ ...prev, email: value }));
    if (name === "emailConfirm")
      return setContactData((prev) => ({ ...prev, emailConfirm: value }));
  }

  return (
    <PageCard>
      <Fade in timeout={700}>
        <Grid
          container
          spacing={spacing}
          sx={{ p: { xs: 3, md: 4 } }}
          component="form"
          id="addNewOrderForm"
          onSubmit={handleConfirmClick}
        >
          <SectionTitle title="Kontaktdaten" disableTopSpace />
          <ContactDataGridItems
            contactData={contactData}
            handleChange={handleChange}
            disabled={disabled}
            showAlert={showEmailAlert}
          />
          <SectionTitle title="Abholdatum" />
          <Grid item md={6} xs={12}>
            <DateSelect
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
              disabled={disabled}
              showAlert={showDateAlert}
              userId={userId}
            />
          </Grid>
          <SectionTitle title="Bestellung" />
          {config.allowCustomOrders && (
            <Grid item xs={12}>
              <FormControlLabel
                disabled={disabled}
                control={
                  <Switch
                    checked={isCustomOrder}
                    onChange={() => {
                      setIsCustomOrder(!isCustomOrder);
                    }}
                  />
                }
                label="Sonderbestellung"
              />
            </Grid>
          )}
          {!isCustomOrder ? (
            orderedProducts.map((product, index) => (
              <Grid item md={6} xs={12} key={`product${index}`}>
                <ProductSelect
                  userId={currentUser.uid}
                  index={index}
                  orderedProducts={orderedProducts}
                  setOrderedProducts={setOrderedProducts}
                  config={config}
                  disabled={disabled}
                />
              </Grid>
            ))
          ) : (
            <Fade in timeout={fadeDuration}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  value={customOrder}
                  onChange={(event) => {
                    setCustomOrder(capitalize(event.target.value));
                  }}
                  id="outlined-multiline-static"
                  label="Bestellnachricht"
                  multiline
                  minRows={4}
                  disabled={disabled}
                />
              </Grid>
            </Fade>
          )}
          {!isCustomOrder && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                size="large"
                onClick={addProduct}
                disabled={disabled}
              >
                Produkt hinzufügen
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              loading={saving}
              loadingPosition="start"
              startIcon={submitButtonIcon}
              variant="contained"
              size="large"
              form="addNewOrderForm"
            >
              Hinzufügen bestätigen
            </LoadingButton>
          </Grid>
        </Grid>
      </Fade>
    </PageCard>
  );
}
