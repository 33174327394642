import React, { useState, useRef, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  Button,
  Toolbar,
  Checkbox,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { useTheme } from "@emotion/react";
import { useConfig } from "../../../context/ConfigContext";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../../context/AuthContext";
import {
  toggleOrderCheckbox,
  updateOrderAcceptStatus,
  updateOrderActiveStatus,
} from "../../../../firebase";
import ConfirmModal from "./ConfirmModal";

export default function DataTableRow({
  row,
  index,
  buttonAction,
  customOrders,
}) {
  const theme = useTheme();
  const [openTableRow, setOpenTableRow] = useState(false);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [declineMessage, setDeclineMessage] = useState("");

  const noBottomBorder = !customOrders
    ? { borderBottom: "unset", maxWidth: 400 }
    : {};
  const rowStyle =
    index % 2 === 0 ? { backgroundColor: theme.palette.action.hover } : {};

  const {
    currentUser: { uid },
  } = useAuth();

  const config = useRef(useConfig());
  const showPrices = config.current.showPrices;

  const {
    id: orderId,
    data: {
      contactData: { fname, lname },
      products,
      dateToCollect,
      orderText,
      checked,
    },
  } = row;

  function getSummaries() {
    const productsSummary = products.map((product, index) =>
      index === 0
        ? `${product.count} × ${product.name}`
        : `, ${product.count} × ${product.name}`
    );

    const reducer = (previousValue, currentValue) =>
      previousValue + currentValue;

    const priceSummary = products
      .map((product) => product.price * product.count)
      .reduce(reducer);

    return { productsSummary, priceSummary };
  }

  const orderTextArray = orderText?.split(" ");
  const orderTextWordCount = orderTextArray?.length;

  const rowContent = !customOrders
    ? [
        `${fname} ${lname}`,
        getSummaries().productsSummary,
        dateToCollect.toDate().toLocaleDateString(),
        `${getSummaries().priceSummary} €`,
      ]
    : [
        `${fname} ${lname}`,
        !openTableRow
          ? orderTextArray.splice(0, 5).join(" ") +
            (orderTextWordCount > 5 ? " . . . " : "")
          : orderText,
        dateToCollect.toDate().toLocaleDateString(),
      ];

  function handleAccept() {
    setAcceptModalOpen(true);
  }
  function handleDecline() {
    setDeclineModalOpen(true);
  }

  function handleConfirm() {
    const collection = customOrders ? "customOrders" : "orders";
    if (buttonAction === "collected")
      updateOrderActiveStatus(uid, orderId, collection);
    if (buttonAction === "accept")
      updateOrderAcceptStatus(uid, orderId, collection);
  }

  function handleDeclineConfirm() {
    const collection = customOrders ? "customOrders" : "orders";
    updateOrderActiveStatus(
      uid,
      orderId,
      collection,
      declineMessage === ""
        ? "Leider können wir Ihre Bestellung aktuell nicht entgegennehmen. Wir bitten um Ihr Verständnis."
        : declineMessage
    );
  }

  const handleToggle = () => {
    const collection = customOrders ? "customOrders" : "orders";
    toggleOrderCheckbox(uid, orderId, collection, checked);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        open={acceptModalOpen}
        setOpen={setAcceptModalOpen}
        onConfirm={handleConfirm}
        title={
          buttonAction === "accept"
            ? "Bestellung bestätigen?"
            : "Bestellung wurde Abgeholt?"
        }
        content={
          buttonAction === "accept"
            ? "Möchten Sie die Bestellung bestätigen? Nach Bestätigung wird der Kunde per E-Mail benachrichtigt."
            : "Möchten Sie diese Bestellung als abgeholt markieren? Die Bestellung wird danach unwiderruflich aus der Bestellübersicht entfernt."
        }
      />
      {buttonAction === "accept" && (
        <ConfirmModal
          open={declineModalOpen}
          setOpen={setDeclineModalOpen}
          onConfirm={handleDeclineConfirm}
          title={"Bestellung Ablehnen?"}
          content={
            <>
              <Typography sx={{ mb: 1 }}>
                Folgende Nachricht wird per E-Mail an den Kunden gesendet:
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={5}
                placeholder="Leider können wir Ihre Bestellung aktuell nicht entgegennehmen. Wir bitten um Ihr Verständnis."
                value={declineMessage}
                onChange={(event) => setDeclineMessage(event.target.value)}
              />
            </>
          }
        />
      )}
      <TableRow hover sx={rowStyle}>
        <TableCell
          sx={{ ...noBottomBorder, width: 100 }}
          onClick={() => setOpenTableRow(!openTableRow)}
        >
          {customOrders && orderTextWordCount <= 5 ? null : (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenTableRow(!openTableRow)}
            >
              {openTableRow ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )}
        </TableCell>
        {buttonAction === "collected" && (
          <TableCell align="center" sx={{ ...noBottomBorder }}>
            <Checkbox checked={checked} onChange={handleToggle} />
          </TableCell>
        )}
        {rowContent.map((data, index) => {
          if (index < 3 || showPrices) {
            let width;
            if (index === 0) width = 300;
            if (index === 1) width = 700;
            return (
              <TableCell
                key={uuidv4()}
                sx={{ ...noBottomBorder, width: width }}
                align={index > 1 ? "right" : "left"}
                onClick={() => setOpenTableRow(!openTableRow)}
              >
                {data}
              </TableCell>
            );
          }
        })}
        <TableCell
          align="center"
          sx={{
            ...noBottomBorder,
          }}
        >
          <Button variant="contained" onClick={handleAccept} size="large">
            {buttonAction === "collected" ? <DoneAllIcon /> : <DoneIcon />}
          </Button>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            ...noBottomBorder,
          }}
        >
          {buttonAction === "accept" && (
            <Button
              variant="contained"
              onClick={handleDecline}
              size="large"
              color="error"
            >
              <ClearIcon />
            </Button>
          )}
        </TableCell>
      </TableRow>
      {!customOrders && (
        <TableRow>
          <TableCell colSpan="100%" sx={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse
              in={openTableRow}
              timeout="auto"
              mountOnEnter
              unmountOnExit
            >
              <Box sx={{ margin: 1 }}>
                <Toolbar
                  sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                  }}
                >
                  <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="h2"
                  >
                    Bestellte Produkte
                  </Typography>
                </Toolbar>
                <Table
                  size="small"
                  aria-label="purchases"
                  sx={!showPrices ? { maxWidth: "50%", minWidth: 500 } : {}}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: 300 }}>Produkt</TableCell>
                      {showPrices && (
                        <TableCell align="right" sx={{ width: 100 }}>
                          Einzelpreis
                        </TableCell>
                      )}
                      <TableCell align="right">Anzahl</TableCell>
                      {showPrices && (
                        <TableCell align="right">Gesamtpreis</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((product) => (
                      <TableRow key={uuidv4()}>
                        <TableCell component="th" scope="row">
                          {product.name}
                        </TableCell>

                        {showPrices && (
                          <TableCell align="right">{product.price} €</TableCell>
                        )}

                        <TableCell align="right">
                          {product.count} Stck.
                        </TableCell>
                        {showPrices && (
                          <TableCell align="right">
                            {Math.round(product.count * product.price * 100) /
                              100}{" "}
                            €
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
