import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useConfig } from "../../../context/ConfigContext";

export default function DataTableHead(props) {
  const { headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const config = useRef(useConfig());

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ color: "white" }} />
        {props.buttonAction === "collected" && <TableCell></TableCell>}
        {headCells.map(
          (headCell, index) =>
            (index < headCells.length - 1 ||
              config.current.showPrices ||
              props.customOrders) && (
              <TableCell
                key={headCell.id}
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
                align={headCell.numeric ? "right" : "left"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {index >= 2 ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    <Box>{headCell.label}</Box>
                    {orderBy === headCell.id ? (
                      <Box
                        component="span"
                        sx={{ ...visuallyHidden, color: "white" }}
                      >
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
            )
        )}
        <TableCell
          align="center"
          sx={{ minWidth: 100, fontWeight: "bold", fontSize: "1.2rem" }}
        >
          {props.buttonAction === "accept" ? "Annehmen" : "Abgeholt"}
        </TableCell>
        {props.buttonAction === "accept" && (
          <TableCell
            align="center"
            sx={{ minWidth: 100, fontWeight: "bold", fontSize: "1.2rem" }}
          >
            Ablehnen
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

DataTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
