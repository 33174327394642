import React, { useState, useEffect } from "react";
import { query, onSnapshot, collection, where, doc } from "firebase/firestore";
import { db } from "../../../../firebase";
import OrderTabs from "../OrderTabs";
import CenteredCircularProgress from "../../../CenteredCircularProgress";
import PageCard from "./PageCard";

export default function NewOrders({ currentUser, selectedBranch }) {
  const [standartOrders, setStandartOrders] = useState(); //! must be null to render content in right order
  const [customOrders, setCustomOrders] = useState(); //! must be null to render content in right order

  useEffect(() => {
    const standartOrderQuery = query(
      collection(db, `users/${currentUser.uid}/orders`),
      where("active", "==", true),
      where("accepted", "==", false),
      where(
        "selectedBranch",
        "==",
        doc(db, `users/${currentUser.uid}/branches`, selectedBranch.id)
      )
    );
    const unsubscribeStandartOrderQuery = onSnapshot(
      standartOrderQuery,
      (querySnapshot) => {
        const newOrders = [];
        querySnapshot.forEach((doc) => {
          newOrders.push({ id: doc.id, data: doc.data() });
        });
        setStandartOrders(newOrders);
      }
    );

    const customOrderQuery = query(
      collection(db, `users/${currentUser.uid}/customOrders`),
      where("active", "==", true),
      where("accepted", "==", false),
      where(
        "selectedBranch",
        "==",
        doc(db, `users/${currentUser.uid}/branches`, selectedBranch.id)
      )
    );
    const unsubscribeCustomOrderQuery = onSnapshot(
      customOrderQuery,
      (querySnapshot) => {
        const newOrders = [];
        querySnapshot.forEach((doc) => {
          newOrders.push({ id: doc.id, data: doc.data() });
        });
        setCustomOrders(newOrders);
      }
    );

    return () => {
      unsubscribeStandartOrderQuery();
      unsubscribeCustomOrderQuery();
    };
  }, [selectedBranch]);

  return (
    <PageCard>
      {standartOrders && customOrders ? (
        <OrderTabs
          standartOrders={standartOrders}
          customOrders={customOrders}
          buttonAction="accept"
        />
      ) : (
        <CenteredCircularProgress />
      )}
    </PageCard>
  );
}
