import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import {
  getProducts,
  getUserByURL,
  addOrder,
  getUserConfig,
  getBranches,
} from "../../../firebase";
import Box from "@mui/material/Box";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Container,
  Alert,
  Stack,
  LinearProgress,
  Typography,
} from "@mui/material";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import CenteredCircularProgress from "../../CenteredCircularProgress";

const steps = [
  {
    label: "Kontaktdaten",
  },
  {
    label: "Abholung",
  },
  {
    label: "Bestellung",
  },
  {
    label: "Zusammenfassung",
  },
];

export default function OrderForm() {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  const [dataProtectionAccepted, setDataProtectionAccepted] = useState(false);

  const [alert, setAlert] = useState("");

  const { companyURL } = useParams();
  const [company, setCompany] = useState({});
  const config = useRef({});
  const offeredProducts = useRef([]);
  const branches = useRef([]);

  const [isCustomOrder, setIsCustomOrder] = useState(false);
  const [customOrder, setCustomOrder] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");

  const emptyProduct = () => ({ id: "", name: "", count: 1, price: null });
  const emptyProductList = () => [emptyProduct()];

  const [contactData, setContactData] = useState({
    fname: "",
    lname: "",
    email: "",
    emailConfirm: "",
  });
  // const today = new Date();
  // const tomorrow = new Date(today);
  // tomorrow.setDate(tomorrow.getDate() + 1);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [orderedProducts, setOrderedProducts] = useState(emptyProductList());

  // Handles the submit of the Steps
  const handleNext = (event) => {
    event.preventDefault();
    setAlert("");

    // Step 1
    if (activeStep === 0 && contactData.email !== contactData.emailConfirm)
      return setAlert("Email-Adressen stimmen nicht überein!");

    // Step 2
    if (activeStep === 1 && !selectedDay)
      return setAlert("Bitte das gewünschte Abholdatum Eingeben!");
    if (activeStep === 1 && selectedDay < new Date())
      return setAlert("Abholen an diesem Tag ist nicht möglich!");

    // Step 3
    if (activeStep === 2) {
      if (!isCustomOrder) {
        let r = false;
        orderedProducts.forEach((product) => {
          if (!product.id) r = true;
        });
        if (r) return;
      }
    }

    //Step 4 - Final Submit and write to database
    if (activeStep === 3) {
      addOrder(
        company.id,
        contactData,
        selectedDay,
        selectedBranch,
        isCustomOrder ? customOrder : orderedProducts,
        (err) => {
          !err ? setSaving(false) : console.log(err);
        }
      );
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setAlert("");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setContactData({
      fname: "",
      lname: "",
      email: "",
      emailConfirm: "",
    });
    setSelectedDay(new Date());
    setOrderedProducts([{ data: null, count: 1 }]);
    setActiveStep(0);
  };

  useEffect(() => {
    process.env.NODE_ENV === "development" &&
      setContactData({
        fname: "Max",
        lname: "Mustermann",
        email: "tim.schulz.rc@gmail.com",
        emailConfirm: "tim.schulz.rc@gmail.com",
      });

    getUserByURL(companyURL, ({ user }) => {
      if (user) {
        const uid = user.id;
        setCompany(user);
        getProducts(uid, (products) => (offeredProducts.current = products));
        getUserConfig(uid, (c) => (config.current = c));
        getBranches(uid, (b) => {
          branches.current = b;
          setSelectedBranch(b[0].id);
        });
      } else {
        console.error("Nutzer wurde nicht gefunden");
      }
      setLoading(false);
    });
  }, []);

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      {loading ? (
        <CenteredCircularProgress />
      ) : company.data ? (
        <Paper sx={{ p: { xs: 1.5, sm: 5 } }} variant="outlined">
          <Typography component="h1" variant="h1">
            {company.data.name}
          </Typography>
          <Typography component="h2" variant="h2" sx={{ mb: 3 }}>
            Bestellformular
          </Typography>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => {
              return (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent sx={{ pl: { xs: 1.5, sm: 3 } }}>
                    <Box
                      sx={{ mb: 2, pt: 3 }}
                      component="form"
                      onSubmit={handleNext}
                      id={`form${index}`}
                    >
                      {index === 0 && (
                        <Step1
                          contactData={contactData}
                          setContactData={setContactData}
                        />
                      )}
                      {index === 1 && (
                        <Step2
                          companyId={company.id}
                          selectedDay={selectedDay}
                          setSelectedDay={setSelectedDay}
                          branches={branches.current}
                          selectedBranch={selectedBranch}
                          setSelectedBranch={setSelectedBranch}
                        />
                      )}
                      {index === 2 && (
                        <Step3
                          userId={company.id}
                          products={offeredProducts.current}
                          isCustomOrder={isCustomOrder}
                          setIsCustomOrder={setIsCustomOrder}
                          customOrder={customOrder}
                          setCustomOrder={setCustomOrder}
                          index={index}
                          orderedProducts={orderedProducts}
                          setOrderedProducts={setOrderedProducts}
                          config={config.current}
                        />
                      )}
                      {index === 3 && (
                        <Step4
                          contactData={contactData}
                          orderedProducts={orderedProducts}
                          selectedDay={selectedDay}
                          config={config.current}
                          isCustomOrder={isCustomOrder}
                          customOrder={customOrder}
                          dataProtectionAccepted={dataProtectionAccepted}
                          setDataProtectionAccepted={setDataProtectionAccepted}
                        />
                      )}
                      {alert && (
                        <Alert severity="warning" sx={{ mt: 2 }}>
                          {alert}
                        </Alert>
                      )}

                      <Stack sx={{ mt: 2, mr: 1 }} direction="row" spacing={2}>
                        <Button
                          type="submit" //! enable submit
                          // onClick={handleNext} //! disable onClick
                          form={`form${index}`}
                          variant="contained"
                          size="large"
                          disabled={
                            index === steps.length - 1 &&
                            !dataProtectionAccepted
                          }
                        >
                          {index === steps.length - 1
                            ? "ABSCHLIESSEN"
                            : "WEITER"}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          variant="outlined"
                          size="large"
                        >
                          ZURÜCK
                        </Button>
                      </Stack>
                    </Box>
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length &&
            (saving ? (
              <LinearProgress />
            ) : (
              <>
                <Alert severity="success" sx={{ mt: 3, mb: 3 }}>
                  Ihre Bestellung wurde erfolgreich abgesendet.
                </Alert>
                <Button variant="contained" onClick={handleReset}>
                  Erneut bestellen
                </Button>
              </>
            ))}
        </Paper>
      ) : (
        <h1>Nutzer konnte nicht gefunden werden</h1>
      )}
    </Container>
  );
}
