import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./components/pages/home/Home";
import Theme from "./components/Theme";
import Signup from "./components/Authentication/Signup/Signup";
import AuthProvider from "./components/context/AuthContext";
import Login from "./components/Authentication/Login";
import PrivateRoute from "./components/routeComponents/PrivateRoute";
import RestrictedRoute from "./components/routeComponents/RestrictedRoute";
import OrderForm from "./components/pages/orderForm/OrderForm";
import OrderManager from "./components/pages/orderManager/OrderManager";
import Settings from "./components/pages/settings/Settings";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import DataProtection from "./components/pages/DataProtection";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE);

export default function App() {
  return (
    <Theme>
      <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route exact path="/" element={<Navigate to="/login" />} />
          {/* <Home /> */}
          <Route exact path="/order/:companyURL" element={<OrderForm />} />
          <Route
            exact
            path="/dashboard"
            element={
              <AuthProvider>
                <PrivateRoute component={OrderManager} />
              </AuthProvider>
            }
          />

          <Route
            exact
            path="/settings"
            element={
              <AuthProvider>
                <PrivateRoute component={Settings} />
              </AuthProvider>
            }
          />

          <Route
            exact
            path="/login"
            element={
              <AuthProvider>
                <RestrictedRoute component={Login} />
              </AuthProvider>
            }
          />
          {/* <Route exact path="/signup" element={<Signup />} /> */}
          <Route exact path="/datenschutz" element={<DataProtection />} />

          <Route path="*" element={404}></Route>
        </Routes>
      </Router>
    </Theme>
  );
}
