import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import DataTableHead from "./DataTableHead";
import DataTableRow from "./DataTableRow";
import emptyList from "../../../../assets/images/clip-list-is-empty.png";

function descendingComparator(a, b, orderBy) {
  if (b.data[orderBy] < a.data[orderBy]) {
    return -1;
  }
  if (b.data[orderBy] > a.data[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function OutlinedPaper(props) {
  return <Paper variant="outlined" {...props} />;
}

export default function DataTable({
  orders,
  headCells,
  buttonAction,
  customOrders,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("dateToCollect");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

  return (
    <Box key="data_table_container" disableGutters>
      {/* <Paper variant="outlined" sx={{ width: "100%", mb: 2 }}> */}
      <TableContainer
        sx={{ overflow: { sm: "scroll", md: "auto" } }}
        component={OutlinedPaper}
      >
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <DataTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={orders?.length}
            customOrders={customOrders}
            buttonAction={buttonAction}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 orders.slice().sort(getComparator(order, orderBy)) */}

            {stableSort(orders, getComparator(order, orderBy))
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((order, index) => {
                return (
                  <DataTableRow
                    key={uuidv4()}
                    row={order}
                    index={index}
                    buttonAction={buttonAction}
                    customOrders={customOrders}
                  />
                );
              })}
            {orders.length === 0 && (
              <TableRow>
                <TableCell colSpan="100%">
                  <Stack alignItems="center">
                    <Typography variant="h6" pt={5}>
                      Es gibt nichts anzuzeigen
                    </Typography>
                    <img src={emptyList} style={{ maxHeight: "300px" }} />
                  </Stack>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan="100%" />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        labelRowsPerPage="Pro Seite"
        count={orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      {/* </Paper> */}
    </Box>
  );
}

DataTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableTitle: PropTypes.string,
  buttonAction: PropTypes.oneOf(["collected", "accept"]).isRequired,
};
