import { Paper } from "@mui/material";

export default function SettingsCard({ children, sx }) {
  return (
    <Paper
      sx={{
        p: 5,
        w: "100%",
        ...sx,
      }}
      variant="outlined"
    >
      {children}
    </Paper>
  );
}
