import React, { useState, useEffect } from "react";
import {
  FormControlLabel,
  Grid,
  Typography,
  IconButton,
  FormGroup,
  Box,
  Tooltip,
} from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import deLocale from "date-fns/locale/de";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import SettingsCard from "./SettingsCard";
import Weekday from "./Weekday";
import { v4 as uuidv4 } from "uuid";
import { getInformation, updateInformation } from "../../../firebase";
import { useAuth } from "../../context/AuthContext";
import CenteredCircularProgress from "../../CenteredCircularProgress";
import { shiftArray, unShiftArray } from "../../../util/tools";

export default function OpeningHoursCheckboxes() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);

  const [openingHours, setOpeningHours] = useState();

  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    if (edit)
      updateInformation(currentUser.uid, {
        openingHours: unShiftArray(openingHours),
      });
    setEdit((prev) => !prev);
  };

  useEffect(() => {
    getInformation(currentUser.uid, (data) => {
      console.log(data);
      setOpeningHours(shiftArray(data.openingHours));
      setLoading(false);
    });
  }, []);

  return (
    <SettingsCard>
      <Typography component="h2" variant="h2" mb={2}>
        Öffnungszeiten
      </Typography>
      <Typography variant="subtitle2" component="p">
        Passt Ihre regulären Öffnungszeiten an, so wie sie auch im
        Betsellformular der Kunden angezeigt werden.
      </Typography>
      {loading ? (
        <CenteredCircularProgress />
      ) : (
        <FormGroup>
          <Box
            sx={{
              display: "flex",
              ml: "auto",
              mb: 3,
              mt: 3,
              alignItems: "center",
            }}
          >
            <Tooltip
              title={
                edit ? "Änderungen Speichern" : "Öffnungszeiten Bearbeiten"
              }
            >
              <IconButton
                color="secondary"
                onClick={handleEdit}
                label="Bearbeiten"
              >
                {edit ? <SaveIcon /> : <EditIcon />}
              </IconButton>
            </Tooltip>
          </Box>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
            <Grid container spacing={3}>
              {openingHours.map((weekday, index) => (
                <Weekday
                  key={`weekday${index}`}
                  index={index}
                  label={weekday.label}
                  name={weekday.name}
                  value={openingHours[index]}
                  edit={edit}
                  setOpeningHours={setOpeningHours}
                />
              ))}
            </Grid>
          </LocalizationProvider>
        </FormGroup>
      )}
    </SettingsCard>
  );
}
