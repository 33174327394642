import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ProfileMenu from "./ProfileMenu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavLink } from "react-router-dom";
import dailyorganizedLogo from "../../assets/svg/dailyorganized-white.svg";

export default function DOAppBar({ handleDrawerToggle, noSideBar }) {
  return (
    <AppBar
      position="fixed"
      sx={{
        //     width: { sm: `calc(100% - ${drawerWidth}px)` },
        //     ml: { sm: `${drawerWidth}px` },
        boxShadow: "none",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={{ 
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}>
        <div 
          style={{ 
            display: "flex", 
            flexDirection: "row", 
            flexWrap: "nowrap",
        }}>
        {noSideBar ? (
          <NavLink to="/dashboard">
            <IconButton sx={{ mr: 2, color: "white" }}>
              <ArrowBackIcon />
            </IconButton>
          </NavLink>
        ) : (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        )}
        
          <img src={dailyorganizedLogo} 
            alt=" Dailyorganized-logo"
            style={{ height: "40px",}}
          />
        </div>
        <ProfileMenu />
      </Toolbar>
    </AppBar>
  );
}
