import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box, Grow, Fade, useMediaQuery } from "@mui/material";
import DataTable from "./datatable/DataTable";
import { useConfig } from "../../context/ConfigContext";
import { standardOrderHeadCells, customOrderHeadCells } from "./headcells";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/styles";

const timeout = 700;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function OrderTabs({
  standartOrders,
  customOrders,
  buttonAction,
}) {
  const [value, setValue] = useState(0);
  const config = useConfig();

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),

      "&.Mui-selected": {
        color: "#000",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
      border: "none",
    })
  );

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      <Tabs
        TabIndicatorProps={{
          style: {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
          },
          children: (
            <span
              style={{
                maxWidth: 250,
                width: "100%",
                backgroundColor: theme.palette.primary.main,
                borderRadius: 50,
              }}
            />
          ),
        }}
        value={value}
        onChange={handleChange}
        aria-label="styled tabs"
        variant={
          useMediaQuery(theme.breakpoints.only("xs"))
            ? "scrollable"
            : "fullWidth"
        }
        scrollButtons="auto"
      >
        {(!config.autoAcceptStandardOrders || buttonAction === "collected") && (
          <StyledTab label="Standartbestellungen" />
        )}
        {config.allowCustomOrders && <StyledTab label="Sonderbestellungen" />}
      </Tabs>

      {(!config.autoAcceptStandardOrders || buttonAction === "collected") && (
        <TabPanel value={value} index={0}>
          <Fade in timeout={timeout}>
            <Box>
              <DataTable
                orders={standartOrders}
                headCells={standardOrderHeadCells}
                buttonAction={buttonAction}
              />
            </Box>
          </Fade>
        </TabPanel>
      )}
      {config.allowCustomOrders && (
        <TabPanel
          value={value}
          index={
            config.autoAcceptStandardOrders && buttonAction !== "collected"
              ? 0
              : 1
          }
        >
          <Fade in timeout={timeout}>
            <Box>
              <DataTable
                orders={customOrders}
                headCells={customOrderHeadCells}
                buttonAction={buttonAction}
                customOrders
              />
            </Box>
          </Fade>
        </TabPanel>
      )}
    </Box>
  );
}
