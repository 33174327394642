import PropTypes from "prop-types";
import {
  Fade,
  ListItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  customOutline: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#152948",
    }
  }
});

export default function BranchSelect({
  branches,
  selectedBranch,
  handleBranchSelect,
  sx,
}) {
  const classes = useStyles();
  return (
    <Fade in timeout={700}>
      <ListItem sx={{ p: 0, mt: 10, ...sx }}>
        <FormControl 
          variant="outlined"
          classes={{ root: classes.customOutline }}
          fullWidth
        >
          <InputLabel id="branchesSelect">Filiale</InputLabel>
          <Select
            disabled={branches.length <= 1}
            value={selectedBranch}
            onChange={handleBranchSelect}
            fullWidth
            label="Filiale"
            labelId="branchesSelect"
          >
            {branches.map((branch) => (
              <MenuItem key={uuidv4()} value={branch}>
                {branch.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItem>
    </Fade>
  );
}

BranchSelect.propTypes = {
  branches: PropTypes.array.isRequired,
  selectedBranch: PropTypes.object.isRequired,
  handleBranchSelect: PropTypes.func.isRequired,
  sx: PropTypes.object,
};
