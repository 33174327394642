import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";

let homeTheme = createTheme({
  shape: { borderRadius: 15 },
  palette: {
    mode: "light",
    primary: {
      main: "#152948", //#00A2D0
      lighter: "#D3EAFF",
    },
    secondary: {
      main: "#EF864D", //#FC6A3D
      contrastText: "#000",
    },
    common: {
      black: "#000",
      white: "#fff",
    },
    tonalOffset: "0.3",
  },
  typography: {
    h1: {
      fontSize: "4rem",
      fontWeight: "600",
      letterSpacing: "5px",
    },
    h2: {
      fontSize: "2.875rem",
    },
    h3: {
      fontSize: "2.562rem",
    },
    h4: {
      fontSize: "2.312rem",
    },
    h5: {
      fontSize: "2rem",
    },
    h6: {
      fontSize: "1.8rem",
      lineHeight: "1",
    },
    subheadline: {
      fontSize: "1.4rem",
      fontFamily: "'Libre Franklin', sans-serif",
    },
    body1: {
      fontFamily: "'Libre Franklin', sans-serif",
    },
    body2: {
      fontFamily: "'Libre Franklin', sans-serif",
    },
    button: {
      fontFamily: "'Libre Franklin', sans-serif",
    },
    caption: {
      fontFamily: "'Libre Franklin', sans-serif",
    },
    overline: {
      fontFamily: "'Libre Franklin', sans-serif",
    },
  },
});

homeTheme = responsiveFontSizes(homeTheme, {
  factor: 2,
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
});

export default function HomeTheme({ children }) {
  return <ThemeProvider theme={homeTheme}>{children}</ThemeProvider>;
}
