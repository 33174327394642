import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import StoreIcon from "@mui/icons-material/Store";
import { v4 as uuidv4 } from "uuid";
import { useTheme } from "@mui/styles";
import { useAuth } from "../context/AuthContext";
import { useConfig } from "../context/ConfigContext";
import { getBranches } from "../../firebase";
import {
  Box,
  Container,
  CssBaseline,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Stack,
  Paper,
} from "@mui/material";
import MiniDrawer from "./MiniDrawer";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";

import CenteredCircularProgress from "../CenteredCircularProgress";
import BranchSelect from "../selects/BranchSelect";
import ConfigProvider from "../context/ConfigContext";
import Sidebar from "./Sidebar";
import DOAppBar from "./DOAppBar";

const drawerWidth = 230;

const drawerItems = [
  // { name: "Übersicht", Icon: <DashboardIcon /> },
  { name: "Aktiv", Icon: <FormatListBulletedIcon /> },
  { name: "Aufnehmen", Icon: <AddCircleOutlineIcon /> },
  { name: "Anfragen", Icon: <FiberNewIcon /> },
  { name: "Produkte", Icon: <ShoppingBasketIcon /> },
];

export default function Layout({ children, onPageChange, page, window }) {
  const sidebarItemStyle = (index) => {
    return {
      color:
        index !== null && index === page ? "secondary.main" : "primary.main",
    };
  };

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const { currentUser } = useAuth();
  const switchToPage = onPageChange;

  const [loadingBranches, setLoadingBranches] = useState(true);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(branches[0]); //TODO: set default do user specific

  useEffect(() => {
    getBranches(currentUser.uid, (branches) => {
      setBranches(branches);
      setSelectedBranch(branches[0]);
      setLoadingBranches(false);
    });
  }, []);

  const handleBranchSelect = (event) => setSelectedBranch(event.target.value);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const childrenWithProps = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, { selectedBranch });
  });

  return (
    <ConfigProvider uid={currentUser.uid}>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <CssBaseline />
        <DOAppBar handleDrawerToggle={handleDrawerToggle} />

        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block", md: "none" },
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <Sidebar
            drawerItems={drawerItems}
            page={page}
            branches={branches}
            selectedBranch={selectedBranch}
            handleBranchSelect={handleBranchSelect}
            loadingBranches={loadingBranches}
            switchToPage={switchToPage}
            setMobileOpen={setMobileOpen}
          />
        </Drawer>
        <MiniDrawer
          open={mobileOpen}
          setOpen={setMobileOpen}
          sx={{
            display: { xs: "none", md: "block", lg: "none" },
          }}
        >
          {drawerItems.map((item, index) => (
            <ListItem
              button
              key={uuidv4()}
              onClick={() => {
                switchToPage(index);
                setMobileOpen(false);
              }}
            >
              <ListItemIcon sx={sidebarItemStyle(index)}>
                {item.Icon}
              </ListItemIcon>
              <ListItemText primary={item.name} sx={sidebarItemStyle(index)} />
            </ListItem>
          ))}
          {/* Branch select commented out at the end of file */}
        </MiniDrawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            paddingBottom: "1rem",
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            maxWidth: "100vw",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ padding: 3 }}>
            <Stack
              direction="row"
              spacing={{ xs: 0, lg: 2 }}
              sx={{ width: "100%" }}
            >
              <Paper
                variant="outlined"
                sx={{
                  display: { xs: "none", lg: "block" },
                  height: "max-content",
                  position: "sticky",
                  top: 89,
                  borderColor: "primary.main",
                }}
              >
                <Stack>
                  <Sidebar
                    drawerItems={drawerItems}
                    page={page}
                    branches={branches}
                    selectedBranch={selectedBranch}
                    handleBranchSelect={handleBranchSelect}
                    loadingBranches={loadingBranches}
                    switchToPage={switchToPage}
                    setMobileOpen={setMobileOpen}
                  />
                </Stack>
              </Paper>
              <Box sx={{ px: "0.5rem", width: "100%" }}>
                {!loadingBranches && childrenWithProps}
              </Box>
            </Stack>
          </Container>
        </Box>
      </Box>
    </ConfigProvider>
  );
}

Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

// {
//   mobileOpen ? (
//     !loadingBranches ? (
//       <BranchSelect
//         branches={branches}
//         selectedBranch={selectedBranch}
//         handleBranchSelect={handleBranchSelect}
//         sx={{ p: 2 }}
//       />
//     ) : (
//       <CenteredCircularProgress />
//     )
//   ) : (
//     <ListItem button sx={{ mt: 12 }} onClick={() => setMobileOpen(true)}>
//       {/* // TODO: 100 height flex align end */}
//       <ListItemIcon sx={sidebarItemStyle()}>
//         <StoreIcon />
//       </ListItemIcon>
//       {/* // TODO: on click open drawer */}
//     </ListItem>
//   );
// }
