import ProductSelect from "../../selects/ProductSelect";
import {
  Button,
  Stack,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import capitalize from "@mui/utils/capitalize";

export default function Step3(props) {
  const {
    userId,
    orderedProducts,
    setOrderedProducts,
    isCustomOrder,
    setIsCustomOrder,
    customOrder,
    setCustomOrder,
    config,
  } = props;

  const emptyProduct = () => ({ id: "", name: "", count: 1, price: null });

  function addProduct() {
    setOrderedProducts((prev) => [...prev, emptyProduct()]);
  }

  return (
    <div>
      <Stack spacing={2} mb={2}>
        {config.allowCustomOrders && (
          <FormControlLabel
            control={
              <Switch
                checked={isCustomOrder}
                onChange={() => {
                  setIsCustomOrder(!isCustomOrder);
                }}
              />
            }
            label="Sonderbestellung"
          />
        )}
        {!isCustomOrder ? (
          orderedProducts.map((product, index) => {
            return (
              <ProductSelect
                key={`product${index}`}
                {...props}
                index={index}
                userId={userId}
              />
            );
          })
        ) : (
          <TextField
            required
            fullWidth
            value={customOrder}
            onChange={(event) => {
              setCustomOrder(capitalize(event.target.value));
            }}
            id="outlined-multiline-static"
            label="Bestellnachricht"
            multiline
            minRows={4}
          />
        )}
      </Stack>
      {!isCustomOrder && (
        <Button variant="outlined" onClick={addProduct} sx={{ mb: 2 }}>
          Produkt hinzufügen
        </Button>
      )}
    </div>
  );
}
