export const standardOrderHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Voller Name",
  },
  {
    id: "order",
    numeric: false,
    disablePadding: false,
    label: "Bestellung",
  },
  {
    id: "dateToCollect",
    numeric: true,
    disablePadding: false,
    label: "Abholdatum",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Preis",
  },
];

export const customOrderHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Voller Name",
  },
  {
    id: "order",
    numeric: false,
    disablePadding: false,
    label: "Bestellung",
  },
  {
    id: "dateToCollect",
    numeric: true,
    disablePadding: false,
    label: "Abholdatum",
  },
];
