import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import BranchSelect from "../selects/BranchSelect";
import CenteredCircularProgress from "../CenteredCircularProgress";
import { useConfig } from "../context/ConfigContext";
import { useTheme } from "@mui/styles";
import { v4 as uuidv4 } from "uuid";

export default function Sidebar({
  drawerItems,
  page,
  branches,
  selectedBranch,
  handleBranchSelect,
  loadingBranches,
  switchToPage,
  setMobileOpen,
}) {
  const config = useConfig();
  const theme = useTheme();

  const sidebarItemStyle = (index) => {
    return {
      color:
        index !== null && index === page ? "secondary.main" : "primary.main",
    };
  };

  return (
    <List sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2 }}>
      {drawerItems.map((item, index) => {
        return item.name === "Neue Anfragen" &&
          !config.allowCustomCustomOrders &&
          config.autoAcceptStandardOrders ? null : (
          <ListItem
            button
            key={uuidv4()}
            onClick={() => {
              switchToPage(index);
              setMobileOpen(false);
            }}
            sx={
              index === page
                ? {
                    border: "solid 2px",
                    borderRadius: `${theme.shape.borderRadius}px`,
                    borderColor: "secondary.main",
                  }
                : {
                    border: "solid transparent 2px",
                    borderRadius: `${theme.shape.borderRadius}px`,
                  }
            }
          >
            <ListItemIcon sx={sidebarItemStyle(index)}>
              {item.Icon}
            </ListItemIcon>
            <ListItemText primary={item.name} sx={sidebarItemStyle(index)} />
          </ListItem>
        );
      })}
      {/* {!loadingBranches ? (
        <BranchSelect
          branches={branches}
          selectedBranch={selectedBranch}
          handleBranchSelect={handleBranchSelect}
        />
      ) : (
        <CenteredCircularProgress />
      )} */}
    </List>
  );
}
