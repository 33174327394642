import { useState, useEffect, useRef } from "react";
import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  Box,
  Divider,
  TextField,
  List,
  ListItem,
  ListSubheader,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import DateSelect from "../../selects/DateSelect";
import { getInformation, setInformation } from "../../../firebase";
import { useAuth } from "../../context/AuthContext";
import CenteredCircularProgress from "../../CenteredCircularProgress";
import { defaultOpeningHours } from "../../../util/globalConstants";

export default function Step2({
  selectedDay,
  setSelectedDay,
  branches,
  selectedBranch,
  setSelectedBranch,
  companyId,
}) {
  const openingHours = useRef();
  const [openingHoursLoading, setOpeningHoursLoading] = useState(true);
  const [dateLoading, setDateLoading] = useState(true);
  const [nearestOrderInXDays, setNearestOrderInXDays] = useState();
  const handleChange = (event) => {
    const value = event.target.value;
    if (event.target.name === "branch") setSelectedBranch(value);
  };

  useEffect(() => {
    getInformation(companyId, (data) => {
      const week = [...data.openingHours];
      const sunday = week.shift();
      week.push(sunday);

      openingHours.current = week;
      setOpeningHoursLoading(false);
    });
  }, []);

  return (
    <Stack direction="column" spacing={3}>
      {branches.length > 1 ? (
        <FormControl fullWidth>
          <InputLabel id="branchLabel">Filiale</InputLabel>
          <Select
            id="branchSelect"
            name="branch"
            label="Filiale"
            labelId="branchLabel"
            value={selectedBranch}
            onChange={handleChange}
            required
          >
            {branches.map((branch) => (
              <MenuItem key={uuidv4()} value={branch.id}>
                {branch.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Box>
          <Typography sx={{ fontSize: "1.3rem" }}>
            <strong>Filiale: </strong>
            {branches[0].name}
          </Typography>
          <Divider />
        </Box>
      )}

      <DateSelect
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        userId={companyId}
      />

      {openingHoursLoading ? (
        <CenteredCircularProgress />
      ) : (
        <List subheader={<ListSubheader>Öffnungszeiten</ListSubheader>}>
          {openingHours.current.map((weekday) => {
            let startHours = weekday.start.toDate().getHours();
            startHours = startHours < 10 ? "0" + startHours : startHours;
            let startMinutes = weekday.start.toDate().getMinutes();
            startMinutes =
              startMinutes < 10 ? "0" + startMinutes : startMinutes;
            let endHours = weekday.end.toDate().getHours();
            endHours = endHours < 10 ? "0" + endHours : endHours;
            let endMinutes = weekday.end.toDate().getMinutes();
            endMinutes = endMinutes < 10 ? "0" + endMinutes : endMinutes;
            return (
              <ListItem key={uuidv4()} sx={{ justifyContent: "space-between" }}>
                <Typography>{weekday.label}: </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "50%",
                  }}
                >
                  {weekday.open ? (
                    <>
                      {" "}
                      <Typography>
                        {startHours + ":" + startMinutes + " Uhr"}
                      </Typography>
                      <Typography> bis </Typography>
                      <Typography>
                        {endHours + ":" + endMinutes + " Uhr"}
                      </Typography>
                    </>
                  ) : (
                    <Typography fontStyle="italic">Geschlossen</Typography>
                  )}
                </Box>
              </ListItem>
            );
          })}
        </List>
      )}
    </Stack>
  );
}
